import React from 'react';
import styled from 'styled-components';
import GoogleMap from './GoogleMap';

const Root = styled.div`
  width: 175px;
  max-height: 100%;
  height: auto;
  position: absolute;
  left: 90px;
  top: 28vw;
  padding-top:51px;
  overflow: hidden;
  letter-spacing: 0px;
  z-index: 1;
`;

const Title = styled.div`
  font-size: 20px;
  padding-bottom: 10px;
  padding-top: 30px;
`;

const Info = styled.div`
  font-size: 14px;
`;

const ContactInfo = () => (
  <Root>
    <div>
      <Title>服務時間</Title>
      <Info>週一到週五／Mon-Fri 09:00-17:30</Info>
      <Title>聯絡方式</Title>
      <Info>TEL / +886-2-2627-2100</Info>
      <Info>FAX / +886-2-2659-6324</Info>
      <Info>E-mail / bliss@sendfuel.com</Info>
      <Title>聖得福建設位置</Title>
      <Info>台北市內湖區堤頂大道二段285號12樓</Info>
      <div style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <GoogleMap />
      </div>
    </div>
  </Root>
);

export default ContactInfo;
