import React, { PureComponent } from "react";
import styled from "styled-components";
import Waypoint from "react-waypoint";

const Root = styled.div`
  position: relative;
  flex:${props => ( props.is_full ? "0 0 100%":"0 0 50%" )};
  height: auto;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 80px;
  min-width: 300px;
  transform:  translateY(0%);
  opacity: 1;
  transition: 0.5s transform cubic-bezier(.26,.44,.44,1.2) 0.4s, 0.5s opacity ease-in 0.2s;
  
  &.is-ready-animated {
    transform: translateY(20%);
    opacity: 0;
  }
`;
const Table = styled.table`
  width: calc(100% - 15px);
`;
class InnerWrapper extends PureComponent {
  state = {
    is_ready_animated: true
  };

  render() {
    const { children,is_full } = this.props;
    return (
      <Waypoint
        onEnter={() => {
          this.setState({
            is_ready_animated: false
          });
        }}
      >
        <Root is_full={is_full} className={this.state.is_ready_animated ? "is-ready-animated" : ""} >
          <Table>
            <tbody>{children}</tbody>
          </Table>
        </Root>
      </Waypoint>
    );
  }
}
export default InnerWrapper;
