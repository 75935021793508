import { observable, decorate, action } from "mobx";
import scroll from "./scrollToAnimation";

class NavModel {
  navStyle = "hide";
  showMobileNav = false;
  isCollaspe = false;
  TaipeiMapArea = "中山區";
  isBackgroundOpacity = false;
  ThreeDUrl = "";
  IsRender3D = false;
  landing;
  news;
  about;
  urban;
  building;
  progress;
  contact;
  sales;

  changeNavStyle(value) {
    this.navStyle = value;
  }

  toggleMobileNav(value) {
    this.showMobileNav = value;
  }

  changeIsCollaspe(value) {
    this.isCollaspe = value;
  }

  changeTaipeiMapArea(value) {
    this.TaipeiMapArea = value;
  }

  changeIsBackgroundOpacity(value) {
    this.isBackgroundOpacity = value;
  }

  change3DUrl(value) {
    this.ThreeDUrl = value;
  }

  changeIsRender3D(value) {
    this.IsRender3D = value;
  }

  scrollTo = node => {
    if (node) {
      const offsetTop = this.isCollaspe ? 55 : 62;
      scroll(this.landing, node.offsetTop - offsetTop, 750);
    }
  };
}

decorate(NavModel, {
  navStyle: observable,
  showMobileNav: observable,
  isCollaspe: observable,
  changeNavStyle: action,
  toggleMobileNav: action.bound
});

export default NavModel;
