// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import InnerWrapper from './InnerWrapper';

// const Root = styled.div`
//   position: relative;
//   left: 10%;
//   width: 80%;
//   height: auto;
//   display: inline-block;
//   vertical-align: top;
//   padding-bottom: 40px;
// `;
// const Table = styled.table`
//   width: calc(100% - 15px);
// `;
const Tr = styled.tr``;
const Th = styled.th`
  border-bottom: 1px solid black;
  color: #000000;
  font-size: 18px;
  text-align: left;
`;
const Td = styled.td`
  width: 40%;
  color: #000000;
  font-size: 14px;
`;

const Button = styled.button`
  padding: 11px 12px;
  font-size: 15px;
  letter-spacing: -0.36px;
  background: ${props => props.theme.black};
  color: ${props => props.theme.white};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  outline: 0;

  &:hover {
    background: ${props => props.theme.red};
  }
`;

const TextError = styled.div`
  position: absolute;
  color: ${props => props.theme.red};
`;

const Input = styled.input`
  box-sizing: border-box;
  height: 42px;
  border: ${props =>
    `1px solid ${props.error ? props.theme.red : props.theme.black}`};
  font-size: 15px;
  width: 70%;
  margin-right: 14px;
  outline: 0;
`;

const File = styled.div`
  text-align: left;
`;

const FileLink = styled.a`
  ${props =>
    props.unlock &&
    `
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `};
`;

const UNITS = [
  'bytes',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB'
];

function niceBytes(x) {
  let l = 0;
  let n = parseInt(x, 10) || 0;
  // eslint-disable-next-line
  while (n >= 1024 && ++l) n /= 1024;

  return `${n.toFixed(n >= 10 || l < 1 ? 0 : 1)} ${UNITS[l]}`;
}
const text = { 'text-align': 'left' };
const input = { 'padding-left': '10px' };
class InnerDownload extends Component {
  state = {
    input: '',
    unlock: false,
    error: false
  };

  downloadFile = (name, data) => {
    if (this.state.unlock) {
      window.open(data, '_blank');
    }
  };

  onInput = e => {
    this.setState({
      input: e.target.value
    });
  };

  onVerify = () => {
    if (!this.state.unlock) {
      if (this.state.input === this.props.password) {
        this.setState({
          unlock: true,
          error: false
        });
      } else {
        this.setState({
          error: true
        });
      }
    }
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.onVerify();
    }
  };

  render() {
    const { data } = this.props;

    if (data[0].assets.name) {
      return (
        <InnerWrapper is_full>
          <Tr>
            <Th colSpan={2}>檔案下載</Th>
          </Tr>
          <Tr>
            {/* TODO: 多個樣式 */}
            <Td>
              {data.map((attachment, i) => {
                const { assets } = attachment;
                return (
                  <File key={i}>
                    <FileLink
                      unlock={this.state.unlock}
                      onClick={() =>
                        this.downloadFile(assets.name, assets.url)
                      }
                    >
                      {assets.name} {niceBytes(assets.size)}
                    </FileLink>
                  </File>
                );
              })}
            </Td>
            <Td rowSpan={2} style={text}>
              <span>
                註:下載密碼請洽專案負責人 {this.props.principal}
              </span>
              <br />
              <span>
                連絡電話:{this.props.phonenumber} 分機:{
                  this.props.extension
                }
              </span>
              <br />
              <span> PS:密碼需區分大小寫。</span>
            </Td>
          </Tr>
          {!this.state.unlock ? (
            <Tr>
              <Td style={text}>
                <Input
                  error={this.state.error}
                  placeholder="請輸入密碼解鎖"
                  onChange={this.onInput}
                  value={this.state.input}
                  onKeyPress={this.handleKeyPress}
                  type="password"
                  autoComplete="on"
                  style={input}
                />
                {this.state.error && <TextError>密碼錯誤</TextError>}
                <Button onClick={() => this.onVerify()}>解鎖</Button>
              </Td>
            </Tr>
          ) : (
            <div>已解鎖</div>
          )}
        </InnerWrapper>
      );
    }
    return null;
  }
}

export default InnerDownload;

function fakeClick(obj) {
  const ev = document.createEvent('MouseEvents');
  ev.initMouseEvent(
    'click',
    true,
    false,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  );
  obj.dispatchEvent(ev);
}
