import styled from 'styled-components';

const CardBase = styled.div`
  background: #f5f5f5;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  text-align: center;
  color: #000;

  transition: 0.25s all ease-in-out;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 2px 12px 25px 0 rgba(0, 0, 0, 0.06),
      0 2px 9px 0 rgba(0, 0, 0, 0.08);
  }
`;

export default CardBase;
