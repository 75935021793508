import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import Footer from './Footer';
// import Cover from './Cover';
import News from './News/';
import About from './About';
import AboutV2 from './AboutV2';
import UrbanRenewal from './Urban/UrbanRenewal';
import BuildingView from './BuildingView/index';
import ProjectProgress from './ProjectProgress/index';
import Contact from './Contact';
import Sales from './Sales';
import CoverV2 from '../Components/CoverV2'

const Root = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: scroll;
`;

class Landingpage extends React.Component {
  componentDidMount() {
    if (this.props.location.hash) {
      setTimeout(() => {
        this.props.store.scrollTo(
          this.props.store[`${this.props.location.hash.replace('#', '')}`]
        );
      }, 500);
    }
  }

  render() {
    const { data, prismicCtx, store } = this.props;
    const { news, about, urban, buildingView, progress, sales, coverV2 } = data;
    return (
      <Root
        isCollaspe={store.isCollaspe}
        innerRef={s => {
          store.landing = s;
        }}
      >
        <CoverV2
          info={coverV2}
          onEnter={() =>
            store.isCollaspe
              ? store.changeNavStyle('show')
              : store.changeNavStyle('showTop')
          }
          onLeave={() => store.changeNavStyle('show')} />
        {/* <Cover
          info={cover}
          onEnter={() =>
            store.isCollaspe
              ? store.changeNavStyle('show')
              : store.changeNavStyle('showTop')
          }
          onLeave={() => store.changeNavStyle('show')}
          linkResolver={prismicCtx.linkResolver}
        /> */}
        <div
          ref={s => {
            store.news = s;
          }}
        >
          <News news={news} store={store} />
        </div>
        {/* <div
          ref={s => {
            store.about = s;
          }}
        >
          <About store={store} about={about} linkResolver={prismicCtx.linkResolver} />
        </div> */}
        <div
          ref={s => {
            store.about = s;
          }}
        >
          <AboutV2 store={store} about={about} linkResolver={prismicCtx.linkResolver} />
        </div>
        <div
          ref={s => {
            store.building = s;
          }}
        >
          <BuildingView store={store} buildingView={buildingView} />
        </div>
        <div
          ref={s => {
            store.urban = s;
          }}
        >
          <UrbanRenewal store={store} urban={urban} />
        </div>
        <div
          ref={s => {
            store.progress = s;
          }}
        >
          <ProjectProgress store={store} progress={progress} />
        </div>
        <div
          ref={s => {
            store.sales = s;
          }}
        >
          <Sales store={store} sales={sales} />
        </div>
        <div
          ref={s => {
            store.contact = s;
          }}
        >
          <Contact store={store} />
        </div>
        <Footer />
        <script src="https://ajax.aspnetcdn.com/ajax/jQuery/jquery-3.3.1.min.js" />
      </Root>
    );
  }
}

export default observer(Landingpage);
