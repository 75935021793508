import React, { Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { compose, pure } from "recompose";

import InnerFooter from "../InnerComponents/InnerFooter";

const MainWrapper = styled.div`
  position: ${props => (props.absolute ? "absolute" : "relative")};
  padding-top: ${props => (props.hasTop ? "51px" : 0)};
  color: ${props => props.theme.black};
  text-align: center;
  top: ${props => (props.absolute ? "28vw" : "initial")};
  left: ${props => (props.absolute && props.renderSideBar ? "250px" : "initial")};
  width: ${props => (props.absolute && props.renderSideBar ? "calc( 100% - 250px )" : " 100%")};
  ${props => {
    if (props.height) {
      return `min-height: ${props.height}`;
    }
    return `height: calc(100vh - ${props.store.isCollaspe ? "55px" : "62px"})`;
  }};
  ${"" /* background-color: ${props => props.backgroundColor || 'white'}; */} box-sizing: border-box;
  overflow: ${props => (props.scroll ? "scroll" : "hidden")};
  overflow: visible;
  z-index: 10;
  @media (max-width: 700px) {
    left: 0;
    width: 100%;
  }
`;
const EngTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.48px;
  line-height: 20px;
  padding-top: 5px;
  ${props => {
    if (props.title_color) {
      return `color: ${props.title_color}`;
    }
    return ``;
  }};
`;
const Title = styled.div`
  font-size: 30px;
  letter-spacing: -0.72px;
  font-weight: 500;
  ${props => {
    if (props.title_color) {
      return `color: ${props.title_color}`;
    }
    return ``;
  }};
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: ${props => {
    if (props.bg_opacity === '0.9') return "0.9";
    return (props.bg_url ? '0.75' : '0.9');
  }};
  background-color: ${props => props.backgroundColor || "white"};
  z-index: 0;

`;

const MoreButton = styled.div`
  font-family: Futura;
  letter-spacing: -0.48px;
  position: absolute;
  right: 50px;
  top: 75px;
  width: 112px;
  line-height: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  background: ${props => props.theme.black};
  color: ${props => props.theme.white};
  cursor: pointer;
  transition 0.25s background ease-in-out;
  &:hover {
    background: ${props => props.theme.red};
  }
  @media(max-width: 630px) {
    right: 50%;
    transform: translateX(50%);
    bottom: 50px;
    top: unset;
  }
`;

const LayoutContent = ({
  scroll,
  absolute,
  engTitle,
  title,
  hasMore,
  children,
  hasTop,
  backgroundColor,
  height,
  renderSideBar,
  bg_url,
  title_color,
  bg_opacity,
  ...props
}) => {
  // debugger;
  return (
    <Fragment>
      {!absolute && <Background backgroundColor={backgroundColor} bg_opacity={bg_opacity} bg_url={bg_url} />}
      {/* {scroll && <LayoutBanner />} */}
      <MainWrapper
        scroll={scroll}
        hasTop={hasTop}
        backgroundColor={backgroundColor}
        height={height}
        absolute={absolute}
        renderSideBar={renderSideBar}
        {...props}
      >
        {!absolute && <Title title_color={title_color}>{title}</Title>}
        {!absolute && <EngTitle title_color={title_color}>{engTitle}</EngTitle>}
        {hasMore && (
          <Link to={hasMore}>
            <MoreButton>MORE</MoreButton>
          </Link>
        )}
        {children}
        {/* <BackToTop onClick={onToTop}>TOP</BackToTop> */}
        {scroll && <InnerFooter />}
      </MainWrapper>
    </Fragment>
  );
};

export default compose(pure)(LayoutContent);
