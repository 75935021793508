// @flow
import React, { Component } from "react";
import styled from "styled-components";
import produce from "immer";
import {
  withRouter,
  // Link,
  // matchPath
} from "react-router-dom";
import { Date } from "prismic-reactjs";
import { format } from "date-fns";
import { sortDate } from "../News/DateSort";

const Root = styled.div`
  width: 150px;
  max-height: 100%;
  height: auto;
  position: relative;
  left: 90px;
  top: 0px;
  bottom: 0;
  overflow: visable;
  z-index: 1;
`;

const EachitemWrapper = styled.ul`
  width: 100%;
  text-align: left;
`;

const ItemWrapper = styled.li`
  line-height: 56px;
  width: 100%;
  border-bottom: 1px solid #9b9b9b;
  font-weight: 500;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const ItemTitle = styled.div`
  font-size: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  padding-top: ${(props) => (props.nested ? "16px" : 0)};
  padding-bottom: ${(props) => (props.nested ? "16px" : 0)};

  :hover {
    color: ${(props) => (props.disabled ? "#000" : "#979797")};
  }
`;

const ItemHandler = styled.div`
  background-color: ${(props) => (props.active ? "props.theme.red" : "transparent")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  height: 56px;
  line-height: 56px;
  position: relative;
  &:before {
    content: " ";
    position: absolute;
    width: 120px;
    top: 50%;
    left: -20px;
    height: 24px;
    background-color: ${(props) => (props.active ? "rgba(208, 25, 36,0.9)" : "transparent")};
    z-index: -1;
    transform: translateY(-50%);
    transition: 1.25s background-color ease-in-out;
  }
`;

const SubItemWrapper = styled.ul`
  display: ${(props) => (props.open ? "block" : "none")};
  font-weight: 300;
`;

const SubItemTitle = styled.li`
  position: relative;
  font-size: 14px;
  line-height: 28px;
  color: ${(props) => (props.active ? "#fff" : "#000")};
  cursor: pointer;
  font-weight: ${(props) => (props.active ? 400 : 300)};

  :last-child {
    margin-bottom: 16px;
  }

  &:hover {
    &:before {
      background-color: ${(props) => (props.active ? "rgba(208, 25, 36,0.9)" : "transparent")};
    }
    color: ${(props) => (props.active ? "#fff" : "#979797")};
  }
  &:before {
    content: " ";
    position: absolute;
    width: 100%;
    top: 5%;
    left: -20px;
    height: 90%;
    background-color: ${(props) => (props.active ? "rgba(208, 25, 36,0.9)" : "transparent")};
    z-index: -1;
    transition: 1.25s background-color ease-in-out;
  }
`;

const DateWrapper = styled.div`
  color: #979797;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  line-height: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

class InnerSideBar extends Component {
  state = {
    data: this.props.data,
    open: this.props.status,
  };

  componentDidMount() {
    // console.log(this.props);
    // if (this.props.nested) {
    //   this.onInitNested();
    // }
  }

  componentWillReceiveProps(props) {
    if (props.nested && props.current && props.current !== this.props.current) {
      this.onInitNested(props.current);
    }
  }

  onInitNested = (uid) => {
    const cloneData = produce(this.props.data, (draftState) => {
      Object.keys(draftState).forEach((key) => {
        if (key !== "all") {
          draftState[key].forEach((element) => {
            if (element.uid === uid) {
              // eslint-disable-next-line
              element.active = true;
              this.toggleExpand(key);
            }
          });
        }
      });
    });
    this.setState({
      data: cloneData,
    });
  };

  toggleExpand = (key) => {
    if (this.hasNoData(key)) return;
    const newOpen = Object.assign({}, this.props.status);
    newOpen[key] = !newOpen[key];
    this.setState({
      open: newOpen,
    });
  };

  hasNoData = (key) => this.props.data[key].length < 1;

  ItemClick = (uid) => {
    this.props.history.push(uid);
    const cloneData = produce(this.props.data, (draftState) => {
      Object.keys(draftState).forEach((key) => {
        if (key !== "all") {
          draftState[key].forEach((element) => {
            if (element.uid === uid) {
              this.toggleExpand(key);
              // eslint-disable-next-line
              element.active = true;
              draftState[key].sort((first, second) => {
                if (second.id === element.id) return 1;
                return 0;
              });
            }
          });
        }
      });
    });
    this.setState({
      data: cloneData,
    });
  };

  renderNestedListItem = (data) => {
    if (!data) {
      return null;
    }
    return Object.keys(data).map((key, i) => {
      return (
        <ItemWrapper disabled={this.hasNoData(key)} key={i}>
          <ItemTitle nested onClick={() => this.toggleExpand(key)} disabled={this.hasNoData(key)}>
            {this.props.select[key]}
          </ItemTitle>
          <SubItemWrapper open={this.state.open[key]}>
            {data[key].length > 0 &&
              data[key].map((subData) => (
                <SubItemTitle onClick={() => this.ItemClick(subData.uid)} active={subData.active} key={subData.id}>
                  {subData.title}
                </SubItemTitle>
              ))}
          </SubItemWrapper>
        </ItemWrapper>
      );
    });
  };

  renderListItem = (data) => {
    if (!data) {
      return null;
    }
    if (this.props.news) {
      return this.renderNews(data);
    }
    if (this.props.data.length > 0 && this.props.data[0].type === "urban") {
      return (
        <div>
          {data
            .filter((d) => {
              return d.data.place === this.props.store.TaipeiMapArea;
            })
            .map((d) => (
              <ItemWrapper key={d.id}>
                <ItemTitle>
                  <ItemHandler
                    onClick={() => this.props.history.push(d.uid)}
                    active={d.uid === this.props.match.params.id}
                  >
                    {d.uid}
                  </ItemHandler>
                </ItemTitle>
              </ItemWrapper>
            ))}
        </div>
      );
    }
    return (
      <div>
        {data.map((d) => (
          <ItemWrapper key={d.id}>
            <ItemTitle>
              <ItemHandler onClick={() => this.props.history.push(d.uid)} active={d.uid === this.props.match.params.id}>
                {String(d.uid).toUpperCase()}
              </ItemHandler>
            </ItemTitle>
          </ItemWrapper>
        ))}
      </div>
    );
  };

  renderNews = (data) => {
    //debugger;
    console.log(data, "lalsaldlall");
    return sortDate(data).map((d) => {
      return (
        <ItemWrapper
          key={d.id}
          onClick={() => this.props.history.push(this.props.match.isExact ? `news/${d.uid}` : d.uid)}
        >
          <ItemTitle>{d.data.title}</ItemTitle>
          <DateWrapper>
            {format(Date(d.data.publishdate ? d.data.publishdate : d.first_publication_date), "YYYY/MM/DD")}
          </DateWrapper>
        </ItemWrapper>
      );
    });
  };

  render() {
    return (
      <Root>
        <EachitemWrapper>
          {this.props.nested ? this.renderNestedListItem(this.state.data) : this.renderListItem(this.props.data)}
        </EachitemWrapper>
      </Root>
    );
  }
}

export default withRouter(InnerSideBar);
