import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Link, Route } from 'react-router-dom';

import LayoutWrapper from '../Layout/LayoutWrapper';
import SalesViewInner from './SalesViewInner';
import InnerSideBar from '../InnerComponents/InnerSideBar';
import ViewCard from '../BuildingView/ViewCard';
import { SALES_SELECT } from '../../utils/constants';

const MenuWrapper = styled.div`
  font-family: Roboto;
  font-size: 17px;
  letter-spacing: 4.2px;
  padding-top: 20px;
`;

const Option = styled.span`
  font-weight: ${props => (props.active ? '600' : '300')};
  cursor: pointer;
  transition: 0.25s all ease-in-out;

  &:hover {
    color: #979797;

    span {
      color: ${props => props.theme.black};
    }
  }

  &:not(:first-child):before {
    content: '/';
    padding: 4.2px;
    font-weight: 300;
  }
`;

const Content = styled.ul`
  margin: 0 auto;
  max-width: 1050px;
  padding-left: 0;
  font-size: 0;
  text-align: left;

  @media (max-width: 1050px) {
    text-align: center;
  }
`;

const ListItem = styled.li`
  display: inline-block;
  margin: 35px;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
`;

const DEFAULT_SALES_OPEN = {
  all: false,
  sales: false,
  rent: false,
}

class SalesViewer extends Component {
  state = {
    view: 'all',
    list: null,
    sideBarList: null,
    current: null,
  };

  componentWillMount() {
    const rent = [];
    const rentSimple = [];
    const sales = [];
    const salesSimple = [];
    this.props.sales.results.forEach(building => {
      if (building.data.classification === '出售') {
        sales.push(building);
        salesSimple.push({
          title: building.data.title,
          id: building.id,
          uid: building.uid,
          classification: 'rent'
        });
      } else if (building.data.classification === '出租') {
        rent.push(building);
        rentSimple.push({
          title: building.data.title,
          id: building.id,
          uid: building.uid,
          classification: 'sales'
        });
      }
    });

    const list = {
      all: this.props.sales.results,
      sales,
      rent,
    };

    const sideBarList = {
      all: this.props.sales.results.map(building => ({
        title: building.data.title,
        id: building.id,
        uid: building.uid
      })),
      rent: rentSimple,
      sales: salesSimple
    };

    this.setState({
      list,
      sideBarList
    });
  }

  getBuilding = match => {
    const result = this.props.sales.results.filter(
      building => building.uid === match.params.id
    );
    return result[0];
  };

  selectView = key => {
    this.setState({
      view: key
    });
  };

  render() {
    const { match } = this.props;
    return (
      <LayoutWrapper
        hasTop
        title="租售專區"
        engTitle="Rent & Sales"
        scroll
        absolute
        url="../images/banner/RentSell_Banner.jpg"
        {...this.props}
        renderSideBar={
          !match.isExact && (
            <InnerSideBar
              select={SALES_SELECT}
              status={DEFAULT_SALES_OPEN}
              current={this.state.current}
              data={this.state.sideBarList}
              nested
            />
          )
        }
      >
        {match.isExact && (
          <Fragment>
            <MenuWrapper>
              {Object.keys(SALES_SELECT).map(key => (
                <Option
                  onClick={() => this.selectView(key)}
                  key={key}
                  active={key === this.state.view}
                >
                  {SALES_SELECT[key]}
                </Option>
              ))}
            </MenuWrapper>
            <Content>
              {this.state.list[this.state.view].map(building => (
                <ListItem key={building.id}>
                  <ViewCard to={`${match.url}/${building.uid}`} building={building} key={building.uid} />
                </ListItem>
              ))}
            </Content>
          </Fragment>
        )}
        <Route
          path="/sales/:id"
          render={routeProps => (
            <SalesViewInner
              {...routeProps}
              currentId={(uid) => {
                this.setState({
                  current: uid
                })
              }}
              sales={this.getBuilding(routeProps.match)}
              prismicCtx={this.props.prismicCtx}
            />
          )}
        />
      </LayoutWrapper>
    );
  }
}

export default SalesViewer;
