import React, { PureComponent } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import {
  // Route,
  Link
} from "react-router-dom";

import LayoutWrapper from "../Layout/LayoutWrapper";
import ViewCard from "../BuildingView/ViewCard";

const SliderWrapper = styled.div`
  margin: auto;
  max-width: 1000px;
  padding: 40px 50px;

  @media (max-width: 470px) {
    padding: 0;
  }
`;
const cardstyle = {
  margin: '0 20px 0 20px'
}

class Sales extends PureComponent {
  state = {};

  renderViewCard = () => {
    if (this.props.sales.total_results_size > 3 || window.outerWidth <= 700) {
      return (
        <SliderWrapper>
          <Slider
            centerPadding="40px"
            dots
            speed={500}
            slidesToShow={3}
            slidesToScroll={3}
            responsive={[
              {
                breakpoint: 1000,
                settings: {
                  centerMode: true,
                  centerPadding: "40px",
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 660,
                settings: {
                  centerMode: true,
                  centerPadding: "40px",
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 470,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]}
          >
            {this.props.sales.results.map(building => (
              <ViewCard to={`sales/${building.uid}`} hasMargin building={building} key={building.id} />
            ))}
          </Slider>
        </SliderWrapper>
      );
    }
    return (
      <div style={{ display: "flex", justifyContent: "center" }} >
        {this.props.sales.results.map(building => (
          <ViewCard to={`sales/${building.uid}`} building={building} hasMargin />
        ))}
      </div>
    );
  };

  render() {
    return (
      <LayoutWrapper
        hasTop
        backgroundColor="#F5F5F5"
        title="租售專區"
        engTitle="Rent & Sales"
        hasMore="/sales"
        height="689px"
        bg_url="../images/bg/bg7.jpg"
        {...this.props}
      >
        {this.renderViewCard()}
      </LayoutWrapper>
    );
  }
}

export default Sales;
