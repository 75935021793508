import React from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import ProgressInnerCard from './ProgressInnerCard';
import { sortDate } from './utils';


const Root = styled.div``;

const Title = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 35px;
  font-weight: bold;
`;

const SubTitle = styled.div`
  font-size: 15px;
  font-weight: bold;

  @media (max-width: 1159px) {
    display: none;
  }
`;

const Circle = styled.div`
  width: 17px;
  height: 17px;
  background: ${props => props.theme.red};
  border-radius: 50%;
  box-shadow: ${props => `0 0 0 .8px ${props.theme.red}`};
  border: 2.5px solid #fff;
  margin: auto;
  box-sizing: border-box;
  margin-top: 10px;

  @media (max-width: 1159px) {
    display: none;
  }
`;

const Main = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 40px;
  max-width: 1040px;
  margin: auto;
`;

const Line = styled.div`
  position: absolute;
  top: 5px;
  bottom: 0;
  width: 2px;
  background: ${props => props.theme.black};
  left:50%;
  transform:translateX(-50%);
  @media (max-width: 1159px) {
    display: none;
  }
`;

const LineBall = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background: ${props => props.theme.black};
  bottom: 0;
  left: -3px;
  border-radius: 50%;
`;

const CardWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  ${props => props.even && 'padding-top: 30px;'};

  @media (max-width: 1120px) {
    padding-top: 30px;

    &:first-child {
      padding-top: 0;
    }
  }
`;

const Start = styled.div`
  padding-top: 10px;
  font-size: 15px;

  @media (max-width: 1159px) {
    display: none;
  }
`;

const Desc = styled.div`
  padding-top: 20px;
  text-align: left;
  max-width: 1040px;
  margin: auto;
`;

const ProjectProgressInner = props => {
  const progress = {
    ...props.progress,
    data: {
      ...props.progress.data,
      detail: sortDate(props.progress.data.detail)
    }
  };
  return (
    <Root id="Progressbox">
      <Title>{progress.data.name || props.uid}</Title>
      <Desc>{RichText.render(progress.data.desc, props.linkResolver)}</Desc>
      <SubTitle>工程開始</SubTitle>
      <Main>
        {progress.data.detail.map((d, i) => (
          <CardWrapper even={Boolean(i % 2 !== 0)} key={i}>
            <ProgressInnerCard first={Boolean(i === 0)} detail={d} even={Boolean(i % 2 !== 0)} />
          </CardWrapper>
        ))}
        {progress.data.detail.length % 2 !== 0 && (
          <CardWrapper style={{ width: '410px' }} />
        )}
        <Line>
          <LineBall />
        </Line>
      </Main>
      {progress.data.detail.length > 0 && <Start>結案</Start>}
    </Root>
  );
};

export default ProjectProgressInner;
