import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";
import LayoutWrapper from "../Layout/LayoutWrapper";
import ProgressCard from "./ProgressCard";

const SliderWrapper = styled.div`
  margin: auto;
  max-width: 1000px;
  padding: 40px 50px;

  @media (max-width: 570px) {
    padding: 0;
  }
`;

class ProjectProgress extends Component {
  state = {};
  renderCard = () => {
    if (this.props.progress.total_results_size > 2) {
      return (
        <SliderWrapper>
          <Slider
            centerPadding="40px"
            speed={500}
            slidesToShow={2}
            slidesToScroll={2}
            responsive={[
              {
                breakpoint: 970,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: true,
                  centerPadding: "100px",
                },
              },
              {
                breakpoint: 730,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: false,
                },
              },
            ]}
          >
            {[1, 2, 3].map((building) => (
              <Link key={building} to="/">
                <ProgressCard hasMargin building={building} />
              </Link>
            ))}
          </Slider>
        </SliderWrapper>
      );
    }
    return (
      <SliderWrapper>
        <ProgressCard building={this.props.progress.results[0]} />
      </SliderWrapper>
    );
  };
  render() {
    if (this.props.progress.total_results_size === 0) return null;
    return (
      <LayoutWrapper
        hasTop
        title="工程進度"
        engTitle="Project Progress"
        hasMore="/progress"
        store={this.props.store}
        bg_url="../images/bg/bg7.jpg"
      >
        {this.renderCard()}
      </LayoutWrapper>
    );
  }
}

export default ProjectProgress;
