import styled from 'styled-components';

export const Wrapper = styled.form`
  max-width: 600px;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 700px) {
    padding:20px 20px;
  }
`;

export const Field = styled.div`
  text-align: left;
  ${'' /* padding-right: 20px;
  padding-left: 20px; */}
  padding-bottom: 30px;
`;
export const Label = styled.div`
  padding-bottom: 10px;
`;

export const Input = styled.input`
  font-size: 16px;
  border: 1px solid #b9b9b9;
  outline: 0;
  height: 46px;
  box-sizing: border-box;
  font-size: 18px;
  width: 100%;
  padding:0px 15px;
  &:focus {
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.3);
  }
`;

export const TextArea = styled.textarea`
  font-size: 16px;
  border: 1px solid #b9b9b9;
  outline: 0;
  height: 160px;
  box-sizing: border-box;
  font-size: 18px;
  width: 100%;
  resize: none;
  &:focus {
    box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.3);
  }
`;

export const SubmitButton = styled.button`
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  background: ${props => props.theme.black};
  color: ${props => props.theme.white};
  cursor: pointer;
  transition 0.25s background ease-in-out;
  width: 96px;
  border:none;
  box-sizing: border-box;
  height: 46px;
  &:hover {
    background: ${props => props.theme.red};
  }
`;
