import React from 'react';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';

const Button = styled.div`
  &:before {
    content: '< ';
  }
  cursor: pointer;
  font-family: 'Roboto';
  position: absolute;
  top: 20px;
  left: 90px;
  color: #FFFFFF;
  font-style: italic;
  font-size: 28px;
  font-weight: 300;
  z-index: 1;
  top:60px;
  transition: all .25s ease-in;
  &:hover {
    color: FFFFFF;
  }

@media(max-width: 700px) {
    display: none;
  }
`;

const BackButton = props => (
  <Link to='/'>
    <Button>Back</Button>
  </Link>
);

export default withRouter(BackButton);
