// @flow
import React, { PureComponent } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "../../Scss/SliderAlbumCustom.css";
import Waypoint from "react-waypoint";

const Root = styled.div`
  width: ${props => (props.is_building ? "90%" : "45%")};
  height: auto;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
  margin-bottom: 50px;

  transform: translateX(0%);
  opacity: 1;
  transition: 0.5s transform cubic-bezier(0.26, 0.44, 0.44, 1.2) 0.4s, 0.5s opacity ease-in 0.2s;

  &.is-ready-animated {
    transform: translateX(-20%);
    opacity: 0;
  }
`;
const ViewWrapper = styled.div`
  width: 100%;
  ${"" /* box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2); */} text-align: center;
`;
class InnerSlideAlbum extends PureComponent {
  state = {
    is_ready_animated: true
  };

  render() {
    const { album, is_building } = this.props;
    const style = {
      width: "100%",
      maxHeight: "500px",
      objectFit: "scale-down",
      backgroundColor: "transparent"
    };
    if (album) {
      if (album.length === 1) {
        return (
          <Waypoint
            onEnter={() => {
              this.setState({
                is_ready_animated: false
              });
            }}
          >
            <Root is_building={is_building} className={this.state.is_ready_animated&&!is_building ? "is-ready-animated" : ""}>
              <ViewWrapper>
                <img style={style} src={album[0].photo.url} alt="" />
              </ViewWrapper>
            </Root>
          </Waypoint>
        );
      }
      return (
        <Waypoint
          onEnter={() => {
            this.setState({
              is_ready_animated: false
            });
          }}
        >
          <Root is_building={is_building} className={this.state.is_ready_animated&&!is_building ? "is-ready-animated" : ""}>
            <Slider centerPadding="0px" dots speed={500} slidesToShow={1} slidesToScroll={1}>
              {album.map((x, i) => (
                <div key={i}>
                  <ViewWrapper>
                    <img style={style} src={x.photo.url} alt="" />
                  </ViewWrapper>
                </div>
              ))}
            </Slider>
          </Root>
        </Waypoint>
      );
    }
    return null;
  }
}
export default InnerSlideAlbum;
