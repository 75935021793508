import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

import mapStyle from './mapStyle.json';

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCTnJ7OnvJbMcQIQjV_5uyW9gYPzMNLoXM&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `175px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={15}
    defaultOptions={{ styles: mapStyle }}
    defaultCenter={{ lat: 25.0786177, lng: 121.5633665 }}
  >
    <Marker position={{ lat: 25.0786177, lng: 121.5633665 }} />
  </GoogleMap>
));

export default MyMapComponent;
