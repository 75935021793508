import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { Link, withRouter } from "react-router-dom";
import { Date } from "prismic-reactjs";
// import { TransitionGroup, CSSTransition } from 'react-transition-group';
import CardBase from "../Card";

const Root = styled(CardBase)`
  display: inline-block;
  width: 32vw;
  height: 43.7vw;
  background: #ffffff;
  display: inline-block;
  overflow: hidden;
  margin: 0px 35px 70px 35px;
  @media (max-width: 700px) {
    width: 80%;
    height: 100.7vw;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 30vw;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${(props) => (props.is_smaller ? "35% auto" : "100% auto")};
  background-image: ${(props) => "url(" + props.src + ")"};
  @media (max-width: 700px) {
    height: 60vw;
  }
`;

const Title = styled.div`
  padding-top: 20px;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.33px;
`;

const PostDate = styled.div`
  font-style: italic;
  font-weight: 300;
  letter-spacing: -0.22px;
  paddding-top: 5px;
`;

const Desc = styled.div`
  font-size: 17px;
  letter-spacing: 1.82px;
  height: 50px;
  overflow: hidden;
  padding-left: 40px;
  padding-right: 40px;
  text-overflow: ellipsis;
  line-height: 1.5;
  text-align: left;
  margin-bottom:30px;
}`;

const NewsCard = ({ result }) => {
  const { data } = result;
  return (
    <Root>
      <Link to={`/news/${result.uid}`}>
        {data.album[0].photo.url && <Image src={data.album[0].photo.url} />}
        {!data.album[0].photo.url && <Image src="../images/logo.png" is_smaller />}
        <Title>{data.title && data.title}</Title>
        <PostDate>
          {result.first_publication_date &&
            `發表於 ${format(
              new Date(data.publishdate ? data.publishdate : result.first_publication_date),
              "YYYY/MM/DD"
            )}`}
        </PostDate>
        <Desc>{data.desc[0] && data.desc[0].text}</Desc>
      </Link>
    </Root>
  );
};

export default withRouter(NewsCard);
