export const SELECT = {
  all: '全部',
  sales: '熱銷推案',
  years: '歷年建案',
  invest: '投資興建',
};

export const SALES_SELECT = {
  all: '全部',
  sales: '售屋',
  rent: '租屋',
}
