import React, { Fragment, Component } from "react";
import $ from "jquery";
import styled from "styled-components";
import { Date } from "prismic-reactjs";
import { format } from "date-fns";
import { RichText } from "prismic-reactjs";
import "lightgallery";
import "lg-zoom";
import "../../Scss/lightgallery.min.css";
import "../../Scss/Editor.css";
import InnerYoutube from "../InnerComponents/InnerYoutube";

const Root = styled.div`
  width: 90%;
  margin: auto;
  padding-top: 30px;
`;

const Image = styled.img`
  width: 50%;
  max-height: 630px;
  object-fit: contain;
  float: right;
  margin-bottom: 20px;
  margin-left: 20px;
  cursor: pointer;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 35px;
  letter-spacing: -0.48px;
  text-align: left;
`;

const PostDate = styled.div`
  color: #979797;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  font-style: italic;
`;

const NewsYoutubeBox = styled.div`
  > div {
    display: block;
    min-height: 300px;
  }
  table {
    width: 50%;
    float: right;
  }
  th {
    display: none;
  }
`;

const Desc = styled.div``;
const Clear = styled.div`
  clear: both;
`;
class NewsContent extends Component {
  showGallery(album) {
    let album_photos = [];
    for (let i = 0; i < album.length; i++) {
      album_photos.push({ src: album[i].photo.url });
    }
    $("#Newsbox").lightGallery({
      dynamic: true,
      dynamicEl: album_photos,
    });
  }

  // onLightGallery = node => {
  //   console.log($(node).lightGallery());
  //   debugger;
  //   this.lightGallery = node;
  //   $(node).lightGallery();
  // };

  // componentWillUnmount() {
  //   $(this.lightGallery)
  //     .data("lightGallery")
  //     .destroy(true);
  // }

  render() {
    const { data, ...props } = this.props;
    //debugger;
    return (
      <Root id="Newsbox">
        {data ? (
          <Fragment>
            <Title>{data.data.title && data.data.title}</Title>
            <PostDate>
              {data.first_publication_date &&
                `發表於 ${format(
                  Date(data.data.publishdate ? data.data.publishdate : data.first_publication_date),
                  "YYYY/MM/DD"
                )}`}
            </PostDate>

            <Desc>
              {/* {data.data.album[0].photo.url && <Image src={data.data.album[0].photo.url} />} */}
              {data.data.album[0].photo.url && (
                <Image
                  src={data.data.album[0].photo.url}
                  onClick={() => this.showGallery(data.data.album)}
                  albumcount={data.data.album.length}
                />
              )}
              {/* {data.data.album[0].photo.url && (
                <div id="lightgallery" ref={this.onLightGallery}>
                  <a href={data.data.album[0].photo.url}>
                    <img alt="" src={data.data.album[0].photo.url} />
                  </a>
                  <a href={data.data.album[0].photo.url}>
                    <img alt="" src={data.data.album[0].photo.url} />
                  </a>
                  <a href={data.data.album[0].photo.url}>
                    <img alt="" src={data.data.album[0].photo.url} />
                  </a>
                </div>
              )} */}
              {data.data.desc && RichText.render(data.data.desc, props.prismicCtx.linkResolver)}
              {data.data.movie.url && (
                <NewsYoutubeBox>
                  <InnerYoutube movie={data.data.movie} />
                </NewsYoutubeBox>
              )}
            </Desc>
          </Fragment>
        ) : (
          <Title>Not Found</Title>
        )}
      </Root>
    );
  }
}

export default NewsContent;
