import React, { PureComponent } from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import Waypoint from "react-waypoint";

const Root = styled.div`
  position: relative;
  padding-top: 40px;
  padding-bottom: ${props => (props.is_building ? "0px" : "40px")};
  width: 40%;
  text-align: ${props => (props.is_building ? "center" : "left")};
  color: #000000;
  height: auto;
  margin: auto;
  p {
    ${props => {
      if (props.is_building) {
        return `text-align: center`;
      }
      return ``;
    }};
  }

  transform: translateX(0%);
  opacity: 1;
  transition: 0.5s transform cubic-bezier(0.26, 0.44, 0.44, 1.2) 0.4s, 0.5s opacity ease-in 0.2s;

  &.is-ready-animated {
    transform: translateX(20%);
    opacity: 0;
  }
  @media(max-width: 470px) {
    width: 90%;
  }
`;
const Title = styled.div`
  font-size: 35px;
  font-weight: bold;
`;
const Summary = styled.div`
  font-size: 16px;
`;
class InnerSubTitle extends PureComponent {
  state = {
    is_ready_animated: true
  };

  render() {
    const { title, desc, is_building, linkResolver } = this.props;
    return (
      <Waypoint
        onEnter={() => {
          this.setState({
            is_ready_animated: false
          });
        }}
      >
        <Root is_building={is_building} className={this.state.is_ready_animated&&!is_building ? "is-ready-animated" : ""}>
          <Title>{title}</Title>
          <Summary>{RichText.render(desc, linkResolver)}</Summary>
        </Root>
      </Waypoint>
    );
  }
}

export default InnerSubTitle;
