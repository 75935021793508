import { Date } from "prismic-dom";
import { compareDesc } from "date-fns";

// eslint-disable-next-line
export const sortDate = (detail) => {
  return detail.sort((a, b) => {
    return compareDesc(
      Date(a.data.publishdate !== null ? a.data.publishdate : a.first_publication_date),
      Date(b.data.publishdate !== null ? b.data.publishdate : b.first_publication_date)
    );
  });
};
