// @flow
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import Loadable from 'react-loadable';

import LayoutWrapper from '../Layout/LayoutWrapper';
import ViewCard from './ViewCard';

const SliderWrapper = styled.div`
  margin: auto;
  max-width: 1000px;
  padding: 40px 50px;
  @media (max-width: 470px) {
    padding: 0;
  }
`;

function compare(a, b) {
  const aIndex = a.data.orderindex || 100;
  const bIndex = b.data.orderindex || 100;
  if (aIndex < bIndex) {
    return -1;
  }
  if (aIndex > bIndex) {
    return 1;
  }
  return 0;
}
class BuildingView extends PureComponent {
  state = {};
  renderViewCard = () => {
    if (this.props.buildingView.total_results_size > 3) {
      return (
        <SliderWrapper>
          <Slider
            centerPadding="40px"
            dots
            speed={500}
            slidesToShow={3}
            slidesToScroll={3}
            responsive={[
              {
                breakpoint: 1000,
                settings: {
                  // centerMode: true,
                  // centerPadding: '40px',
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 700,
                settings: {
                  // centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 470,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]}
          >
            {this.props.buildingView.results.sort(compare).map(building => (
              <ViewCard hasMargin building={building} key={building.id} />
            ))}
          </Slider>
        </SliderWrapper>
      );
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {this.props.buildingView.results.sort(compare).map(building => (
          <ViewCard building={building} hasMargin key={building.id} />
        ))}
      </div>
    );
  };

  render() {
    return (
      <LayoutWrapper
        hasTop
        title="建案實績"
        engTitle="Building View"
        hasMore="/buildingview"
        height="689px"
        bg_url="../images/bg/bg7.jpg"
        {...this.props}
      >
        {this.renderViewCard()}
      </LayoutWrapper>
    );
  }
}

export default BuildingView;
