import React from "react";
import styled from "styled-components";

import ELOGO from "../img/footer/elogo.svg";
import NURO from "../img/footer/logo_nuro.svg";
import URO from "../img/footer/logo_uro.svg";
import TWUR from "../img/footer/twur-logo.svg";

const Root = styled.div`
  padding: 30px 50px;
  position: relative;
  padding-right: 300px;
  background: white;
  @media (max-width: 700px) {
    padding: 0px 35px;
    text-align: center;
  }
`;

const Image = styled.img`
  cursor: pointer;
  padding-right: 30px;
  @media (max-width: 700px) {
    width: 40%;
    padding: 0px 10px;

    &.is-special {
      position: relative;
      top: -10px;
    }
  }
`;

const Copright = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  font-size: 12px;
  color: #000;
  @media (max-width: 700px) {
    display: none;
    bottom: auto;
    right: auto;
    position: relative;
    &.is-mobile {
      display: block;
    }
  }
`;

const Footer = () => (
  <Root>
    <Copright className="is-mobile">Copyright © 2017 聖得福建設開發股份有限公司</Copright>
    <Image src={ELOGO} onClick={() => window.open("http://addr.taipei/2014/", "_blank")} />
    <Image className="is-special" src={NURO} onClick={() => window.open("https://www.uro.ntpc.gov.tw/", "_blank")} />
    <Image src={URO} onClick={() => window.open("https://uro.gov.taipei/", "_blank")} />
    <Image src={TWUR} onClick={() => window.open("https://twur.cpami.gov.tw/", "_blank")} />
    <Copright>Copyright © 2017 聖得福建設開發股份有限公司</Copright>
  </Root>
);

export default Footer;
