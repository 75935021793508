import React, { PureComponent } from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";

import LayoutWrapper from "./Layout/LayoutWrapper";
import "../Scss/Editor.css";

const Content = styled.div`
  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 32px;
  position: relative;
  box-sizing: border-box;
`;
const PanelWrapper = styled.div`
  width: 100%;
  text-align: left;
`;
const LeftPanel = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 35.9%;
  box-sizing: border-box;
  margin-left: 11.6vw;
  margin-right: 4.6vw;
  height: auto;
  @media (max-width: 700px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const RightPanel = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 35.9%;
  box-sizing: border-box;
  height: auto;
  @media (max-width: 700px) {
    background-size: auto 100%;
    background-repeat: no-repeat;
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;
const ImageBox1 = styled.div`
  position: relative;
  width: 35.9vw;
  height: 35.9vw;
  background-repeat: no-repeat;
  background-image: url("${props => props.img_url}");
  background-position: center;
  background-size: cover;
  z-index: -1;
  margin-bottom:20px;
  margin-top:50px;
  @media (max-width: 700px) {

    width: 100%;
    height: 100%;
    margin:0;
    padding:0;
  }
`;
const ImageBox2 = styled.div`
  position: relative;
  width: 35.9vw;
  height: 35.9vw;
  background-repeat: no-repeat;
  background-image: url("${props => props.img_url}");
  background-position: center;
  background-size: cover;
  z-index: -1;
  margin-bottom:20px;
  @media (max-width: 700px) {

    width: 100%;
    height: 100%;
    margin:0;
    padding:0;
  }
`;
const IconSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 80px;
`;
const IconWrapper = styled.div`
  position: relative;
  width: 85px;
  height: 85px;
  background-color: ${props => props.theme.black};
  border-radius: 50%;
`;
const Icon = styled.img`
  top: 50%;
  position: absolute;
  width: 54px;
  height: 54px;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const DBar = styled.div`
  position: ralative;
  width: 3.1vw;
  height: 3px;
  background-color: black;
  min-width: 40px;
  margin-left: 0.3em;
  margin-top: 25px;
  margin-bottom: 30px;
`;

class About extends PureComponent {
  render() {
    const { about, linkResolver, store } = this.props;
    //debugger;
    return (
      <LayoutWrapper
        backgroundColor="rgb(245,245,245)"
        bg_opacity="0.9"
        height="auto"
        store={store}
        title="關於我們"
        bg_url="../images/cover/bg_inner_1.jpg"
        engTitle="About"
        hasTop
      >
        {/* <Content className="about">{RichText.render(about.data.desc, linkResolver)}</Content> */}
        <PanelWrapper>
          <LeftPanel>
            {" "}
            {about.data.about_group.map(d => (
              <Content className="about">
                {RichText.render(d.title, linkResolver)}
                <DBar />
                {RichText.render(d.content, linkResolver)}
              </Content>
            ))}{" "}
          </LeftPanel>
          <RightPanel>
            <ImageBox1 img_url={about.data.photo1.url} />
            <ImageBox2 img_url={about.data.photo2.url} />
          </RightPanel>
        </PanelWrapper>

        {/* <IconSection>
          <div>
            <IconWrapper>
              <Icon src="images/about-3.png" />
            </IconWrapper>
            <p>高檔建材</p>
          </div>
          <div>
            <IconWrapper>
              <Icon src="images/about-1.png" />
            </IconWrapper>
            <p>高檔建材</p>
          </div>
          <div>
            <IconWrapper>
              <Icon src="images/about-2.png" />
            </IconWrapper>
            <p>高檔建材</p>
          </div>
        </IconSection> */}
      </LayoutWrapper>
    );
  }
}

export default About;
