import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ClickOutside from 'react-click-outside';

const Root = styled.div`
  background: ${props => props.theme.red};
  position: absolute;
  z-index: 99;
  left: 10px;
  right: 10px;
  top: 70px;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  opacity:0.9;
`;

const ListItem = styled.li`
  line-height: 15px;
  padding: 15px;
  cursor: pointer;
  color: #fff;
  font-size:15px;
  &:hover {
    opacity: 0.8;
  }
`;

class MobileNav extends PureComponent {
  handleClickOutside = () => {
    this.props.handleClickOutside();
  };

  render() {
    const {
      onHomeClick,
      onNewsClick,
      onAboutClick,
      onBuildingClick,
      onUrbanClick,
      onSalesClick,
      onProgressClick,
      onContactClick
    } = this.props;
    return (
      <Root>
        <ul>
          <Link to="/">
            <ListItem onClick={onHomeClick}>首頁</ListItem>
          </Link>
          <ListItem onClick={onNewsClick}>最新消息</ListItem>
          <ListItem onClick={onAboutClick}>關於我們</ListItem>
          <ListItem onClick={onBuildingClick}>建案實績</ListItem>
          <ListItem onClick={onUrbanClick}>都更專案</ListItem>
          <ListItem onClick={onSalesClick}>租售專區</ListItem>
          <ListItem onClick={onProgressClick}>工程進度</ListItem>
          <ListItem onClick={onContactClick}>聯絡我們</ListItem>
        </ul>
      </Root>
    );
  }
}

export default ClickOutside(MobileNav);
