// @flow
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Waypoint from "react-waypoint";
import UrbanContent from './UrbanContent';

const Root = styled.div`
  width: 90%;
  min-height: 671px;
  height: auto;
  position: relative;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  ${'' /* margin-bottom: 90px; */}
  margin-top: 30px;
  padding-top: 40px;
  transform:  translateY(0%);
  opacity: 1;
  transition: 0.5s transform cubic-bezier(.26,.44,.44,1.2) 0.4s, 0.5s opacity ease-in 0.2s;
  
  &.is-ready-animated {
    transform: translateY(20%);
    opacity: 0;
  }
`;
const TabWrapper = styled.div`
  height: 63px;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  text-align: left;
`;
const Tab = styled.div`
  position: relative;
  background: #e8e8e8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  font-size: 15px;
  color: #000000;
  letter-spacing: -0.11px;
  display: inline-block;
  vertical-align: top;
  height: 41px;
  width: auto;
  box-sizing: border-box;
  line-height: 25px;
  padding: 10px;
  margin-left: 30px;
  cursor: pointer;

  ${props =>
    props.isSelected &&
    `
    background: #fff;

    &:before {
      content: ' ';
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 8.71px 12px 8.71px;
      border-color: transparent transparent #d4303a transparent;
    }
  `};
`;

class InnerUrbanRenewal extends PureComponent {
  state = {
    currentTabIndex: 0,
    is_ready_animated: true
  };

  onChangeTab = (i) => {
    this.setState({
      currentTabIndex: i
    })
  }

  render() {
    const { data, linkResovler } = this.props;
    return (
      <Waypoint onEnter={() => {
        this.setState({
          is_ready_animated: false
        });
      }}>
      <Root className={this.state.is_ready_animated ? "is-ready-animated" : ""}>
        <TabWrapper>
          {data.map((d, i) => (
            <Tab key={i} isSelected={Boolean(this.state.currentTabIndex === i)} onClick={() => this.onChangeTab(i)}>
              {d.primary.tab_header || d.primary.header.text || ''}
            </Tab>
          ))}
        </TabWrapper>
        <UrbanContent data={data[this.state.currentTabIndex]} linkResovler={linkResovler} />
      </Root>
      </Waypoint>
    );
  }
}

export default InnerUrbanRenewal;
