// @flow
import React from "react";
import styled from "styled-components";
import YouTube from "react-youtube";

import InnerWrapper from "./InnerWrapper";

const Tr = styled.tr``;
const Th = styled.th`
  border-bottom: 1px solid black;
  color: #000000;
  font-size: 18px;
  text-align: left;
`;
const Td = styled.td`
  width: 100%;
  padding-top: 22px;
`;
const YoutubeWrapper = styled.div`
  width: 100%;
  height: 21vw;
  > div {
    height: 100%;
  }
  @media (max-width: 700px) {
    height: 28vh;
  }
`;

const InnerYoutube = ({ movie }) => {
  const youTubeGetID = () => {
    let id = "";
    const getUrl = movie.url.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (getUrl[2] !== undefined) {
      // eslint-disable-next-line
      const getId = getUrl[2].split(/[^0-9a-z_\-]/i);
      [id] = getId;
    } else {
      id = movie.url;
    }
    return id;
  };

  return (
    <InnerWrapper>
      <Tr>
        <Th>觀看影片</Th>
      </Tr>
      <Tr>
        <Td>
          <YoutubeWrapper>
            <YouTube
              videoId={youTubeGetID()}
              opts={{
                width: "100%",
                height: "100%"
              }}
            />
          </YoutubeWrapper>
        </Td>
      </Tr>
    </InnerWrapper>
  );
};

export default InnerYoutube;
