import React, { Component } from "react";
import styled from "styled-components";
import Waypoint from "react-waypoint";
import $ from "jquery";
import "lightgallery";
import "lg-zoom";
import "../../Scss/lightgallery.min.css";

const Root = styled.div`
  user-select: none;
  position: relative;
  width: 410px;
  height: 220px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  transform: translateY(0%) translateX(0%);
  opacity: 1;
  transition: 0.5s transform ease-in-out 0.25s, 0.5s opacity ease-in 0.25s;
  z-index: 10;
  &.is-ready-animated {
    transform: ${(props) => (props.even ? "translateY(10%) translateX(15%)" : "translateY(10%) translateX(-15%)")};
    opacity: 0;
  }
`;

const Filter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${(props) => props.image && `url(${props.image}) center center`};
  background-size: cover;
  opacity: 0.1;
  cursor: pointer;
`;

const Inner = styled.div`
  height: 183px;
  padding: 20px;
  display: flex;
  justify-content: ${(props) => (props.image ? "space-between" : "center")};
`;

const Image = styled.img`
  width: 215px;
  height: 183px;
`;

const Right = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 150px;
`;

const LatestTag = styled.div`
  display: block;
  width: 88px;
  height: 21px;
  background-color: #000000;
  color: #fff;
  line-height: 21px;
  margin-left: 30px;
  margin-right: 30px;
`;

const InfoTitle = styled.div`
  font-weight: bold;
  /* white-space: pre; */
  line-height: 20px;
`;

const InfoDate = styled.div`
  font-weight: lighter;
  font-style: italic;
  font-size: 12px;
  font-family: Roboto;
`;

const Circle = styled.div`
  width: 17px;
  height: 17px;
  background: ${(props) => props.theme.red};
  border-radius: 50%;
  box-shadow: ${(props) => `0 0 0 .8px ${props.theme.red}`};
  border: 2.5px solid #fff;
  margin: auto;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  ${(props) => {
    if (props.even) {
      return `left: -64px`;
    }
    return `right: -64px`;
  }};
`;

class ProgressInnerCard extends Component {
  showGallery(obj) {
    if (obj.url) {
      let album_box = [];
      let album_obj = { src: obj.url };
      album_box.push(album_obj);

      for (let i = 1; i < 5; i++) {
        if (obj["album" + i].url && obj["album" + i].url != obj.url) {
          album_obj = { src: obj["album" + i].url };
          album_box.push(album_obj);
        }
      }
      //debugger;
      let light_gallery = $("#Progressbox").lightGallery({
        dynamic: true,
        dynamicEl: album_box,
        download: false,
      });
      light_gallery.on("onCloseAfter.lg", function() {
        light_gallery
          .off("onCloseAfter.lg") // <-- unbind
          .data("lightGallery")
          .destroy(true);
      });
    }
  }
  state = {
    is_ready_animated: true,
  };
  render() {
    const { detail, first, even } = this.props;
    //debugger;
    console.log(detail);
    return (
      <Waypoint
        onEnter={() => {
          this.setState({
            is_ready_animated: false,
          });
        }}
      >
        <Root
          className={this.state.is_ready_animated ? "is-ready-animated" : ""}
          even={even}
          onClick={() => this.showGallery(detail.image)}
        >
          {detail.islatest === "是" && <Circle even={even} />}
          <Filter image={detail.image.url} />
          <Inner image={detail.image.url}>
            {detail.image.url && <Image src={detail.image.url} />}
            <Right>
              {detail.islatest === "是" && <LatestTag>最新進度</LatestTag>}
              <InfoTitle>{detail.text}</InfoTitle>
              <InfoDate>{detail.date}</InfoDate>
            </Right>
          </Inner>
        </Root>
      </Waypoint>
    );
  }
}

export default ProgressInnerCard;
