import React from "react";
import styled from "styled-components";
import InnerBanner from "../InnerComponents/InnerBanner";
import LayoutContent from "./LayoutContent";
import BackButton from "../BackButton";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

const Root = styled.div`
  position: relative;
  z-index: 1;
  ${props => {
    if (props.absolute) {
      return `overflow-x: auto`;
    }
    return `overflow: hidden`;
  }};
  overflow: ${props => (props.absolute ? "" : "hidden")};
  ${props => {
    if (props.absolute) {
      return `height: calc( 100vh - 62px )`;
    }
    return "";
  }};
  ${props => {
    if (props.bg_url) {
      return `background: ${"url(" + props.bg_url + ")" || "white"}`;
    }
    return "";
  }};
  background-size: ${props => {
    //debugger;
    return props.bg_opacity ? "cover" : "100% auto";
  }};
  background-position: center center;
  ${"" /* padding-top: ${props => (props.hasTop ? '150px' : '40px')}; */};
  ${"" /* padding-top: ${props => (props.hasTop ? '150px' : '40px')}; */};
  @media (max-width: 700px) {
    background-size: auto 100%;
    background-repeat: no-repeat;
  }
`;

const LeftWarpper = styled.div`
  width: 250px;
  margin-top: 51px;
  @media (max-width: 700px) {
    display: none;
  }
`;

// const BackToTop = styled.div`
//   font-size: 16px;
//   color: #979797;
//   font-style: italic;
//   text-decoration: underline;
//   position: fixed;
//   bottom: 40px;
//   right: 50px;
//   cursor: pointer;
// `;

const LayoutWrapper = ({
  children,
  title,
  engTitle,
  hasMore,
  onToTop,
  scroll,
  backgroundColor,
  height,
  hasTop,
  renderSideBar,
  absolute,
  url,
  bg_url,
  title_color,
  bg_opacity,
  ...props
}) => {
  if (absolute) {
    props.store.changeIsBackgroundOpacity(true);
  } else {
    props.store.changeIsBackgroundOpacity(false);
  }
  if (absolute) {
    return (
      <Root
        hasTop={hasTop}
        backgroundColor={backgroundColor}
        height={height}
        absolute={absolute}
        bg_opacity={bg_opacity}
      >
        {absolute && <InnerBanner url={url} engTitle={engTitle} title={title} />}
        {props.match &&
          renderSideBar && (
            <LeftWarpper>
              {props.match && <BackButton />}
              {renderSideBar && renderSideBar}
            </LeftWarpper>
          )}
        <LayoutContent
          backgroundColor={backgroundColor}
          hasTop={hasTop}
          scroll={scroll}
          engTitle={engTitle}
          title={title}
          hasMore={hasMore}
          height={height}
          absolute={absolute}
          renderSideBar={renderSideBar}
          bg_opacity={bg_opacity}
          {...props}
        >
          {props.match && !renderSideBar && <BackButton />}
          {children}
        </LayoutContent>
      </Root>
    );
  }
  return (
    <Root
      hasTop={hasTop}
      backgroundColor={backgroundColor}
      height={height}
      absolute={absolute}
      bg_url={bg_url}
      bg_opacity={bg_opacity}
    >
      {absolute && <InnerBanner url={url} engTitle={engTitle} title={title} />}
      {props.match &&
        renderSideBar && (
          <LeftWarpper>
            {props.match && <BackButton />}
            {renderSideBar && renderSideBar}
          </LeftWarpper>
        )}
      <LayoutContent
        backgroundColor={backgroundColor}
        hasTop={hasTop}
        scroll={scroll}
        engTitle={engTitle}
        title={title}
        hasMore={hasMore}
        height={height}
        absolute={absolute}
        renderSideBar={renderSideBar}
        bg_url={bg_url}
        title_color={title_color}
        bg_opacity={bg_opacity}
        {...props}
      >
        {props.match && !renderSideBar && <BackButton />}
        {children}
      </LayoutContent>
    </Root>
  );
};

export default LayoutWrapper;
