import React, { Component } from "react";
import styled from "styled-components";
import { Route } from "react-router-dom";
import LayoutWrapper from "../Layout/LayoutWrapper";
import NewsCard from "./NewsCard";
import InnerSideBar from "../InnerComponents/InnerSideBar";
import NewsContent from "./NewsContent";
import { Pagination } from "element-react";
import "element-theme-default";

const Root = styled.div`
  ${"" /* height: 100%; */} position: relative;
  ${"" /* width: 100%; */};
`;

const MainPanel = styled.div`
  padding: 20px;
  height: 100%;
  text-align: left;
`;

class NewsView extends Component {
  getNews = match => {
    const result = this.props.news.results.filter(data => data.uid === match.params.id);
    return result[0];
  };

  render() {
    const { match,paggerchange } = this.props;
    // debugger;
    console.log("=====================")
    console.log(this.props.news)
    console.log("=====================")
    return (
      <LayoutWrapper
        scroll
        hasTop
        engTitle="News"
        title="最新消息"
        absolute
        url="../images/banner/Banner_News.jpg"
        {...this.props}
        renderSideBar={<InnerSideBar data={this.props.news.results} news />}
      >
        <Root>
          {match.isExact && (
            <MainPanel>
              {this.props.news.results.map((result, i) => <NewsCard key={i} result={result} />)}
            </MainPanel>
          )}
          <Route
            path="/news/:id"
            render={routeProps => <NewsContent data={this.getNews(routeProps.match)} {...this.props} {...routeProps} />}
          />
          {match.isExact && (
            <Pagination
              layout="prev, pager, next"
              total={this.props.news.total_results_size}
              pageSize={10}
              onCurrentChange={paggerchange}
            />
          )}
        </Root>
      </LayoutWrapper>
    );
  }
}

export default NewsView;
