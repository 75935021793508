import React, { Component } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import LayoutWrapper from '../Layout/LayoutWrapper';
import ContactInfo from './ContactInfo';
import { Wrapper, Field, Label, Input, TextArea, SubmitButton } from '../Form';

class ContactInner extends Component {
  state = {
    selectedOption: '',
    input: ''
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleInputChange = e => {
    this.setState({
      input: e.target.value
    });
  };

  render() {
    const { selectedOption, input } = this.state;
    const value = selectedOption && selectedOption.value;
    return (
      <LayoutWrapper
        hasTop
        match={this.props.match}
        {...this.props}
        title="聯絡我們"
        engTitle="Contact"
        scroll
        absolute
        renderSideBar={<ContactInfo />}
      >
        <Wrapper
          style={{
            paddingRight: '20px',
            paddingLeft: '20px',
          }}
          action="https://formspree.io/bliss@sendfuel.com"
          method="POST"
        >
          <input type="hidden" name="_subject" value={`[${value}] - ${input}`} />
          <Field>
            <Label>你的姓名 Name*</Label>
            <Input type="text" name="Name" required autoComplete="name" />
          </Field>
          <Field>
            <Label>連絡電話 Phone*</Label>
            <Input type="text" name="phone" required  />
          </Field>
          <Field>
            <Label>連絡信箱 Email*</Label>
            <Input type="email" name="_replyto" required autoComplete="email" />
          </Field>
          <Field>
            <Label>有興趣的服務 Service*</Label>
            <Select
              searchable={false}
              clearable={false}
              name="Service"
              value={value}
              required
              placeholder="請選擇"
              style={{
                borderRadius: 0,
                border: '1px solid #b9b9b9',
                height: '46px',
                fontSize: '16px'
              }}
              onChange={this.handleChange}
              options={[
                {
                  value: '都市更新 Urban Renewal',
                  label: '都市更新 Urban Renewal'
                },
                {
                  value: '開發管理 Development Management',
                  label: '開發管理 Development Management'
                },
                { value: '規劃顧問 Consultant', label: '規劃顧問 Consultant' },
                { value: '其他 Other', label: '其他 Other' }
              ]}
            />
          </Field>
          <Field>
            <Label>主旨 Title*</Label>
            <Input
              type="text"
              name="Title"
              onChange={this.handleInputChange}
              required
              value={input}
            />
          </Field>
          <Field>
            <Label>留下您想說的話 Leave your message*</Label>
            <TextArea name="Message" required />
          </Field>
          <SubmitButton>送出</SubmitButton>
        </Wrapper>
      </LayoutWrapper>
    );
  }
}

export default ContactInner;
