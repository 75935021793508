import Prismic from "prismic-javascript";

export default async function getPrismicContext(ctx, pageIndex) {
  const api = await Prismic.api(ctx.endpoint, {
    accessToken: ctx.accessToken
  });
  // const response = await api.query(Prismic.Predicates.at('document.type', 'cover'));
  const response = {};
  //debugger;
  response.news = await api.query(Prismic.Predicates.at("document.type", "news"), { pageSize: 10, page: pageIndex });

  console.log(response);
  return response;
}
