import React from 'react';
import styled from 'styled-components';
import { lifecycle, compose, pure } from 'recompose';

// import InnerSideBar from '../InnerComponents/InnerSideBar';
import InnerSlideAlbum from '../InnerComponents/InnerSlideAlbum';
import InnerSubTitle from '../InnerComponents/InnerSubTitle';
// import InnerTable from '../InnerComponents/InnerTable';
import Inner3D from '../InnerComponents/Inner3D';
import InnerYoutube from '../InnerComponents/InnerYoutube';

import LayoutBlock from '../Layout/LayoutBlock';

const Root = styled.div`
  ${'' /* height: 100%; */}
  position: relative;
  ${'' /* width: 100%; */}
`;
const MainPanel = styled.div`
  ${'' /* flex: 1; */} ${'' /* bottom: 0px;
  position: absolute;
  left: 300px;
  right: 90px;
  top: 0px;
  overflow: auto; */};
`;


const SalesViewInner = ({
  sales,
  // sideBarList,
  linkResolver,
  // currentId,
  // ...props
}) => {
  if (sales) {
    const { data } = sales;
    return (
      <Root>
        {/* <InnerSideBar data={sideBarList} current={building} nested /> */}
        <MainPanel>
          {/* <InnerTitle /> */}
          <InnerSlideAlbum album={data.album} />
          <InnerSubTitle
            title={data.title}
            desc={data.desc}
            linkResolver={linkResolver}
          />
          <LayoutBlock>
            {/* {data.body.map((table, i) => <InnerTable key={i} data={table} />)} */}
            {data.movie.url && <InnerYoutube movie={data.movie} />}
            {/* <Inner3D></Inner3D> */}
          </LayoutBlock>
        </MainPanel>
      </Root>
    );
  }
  return <Root>Not Found</Root>;
};

export default compose(
  lifecycle({
    componentDidMount() {
      if (this.props.currentId) {
        this.props.currentId(this.props.sales.uid);
      }
    }
  }),
  pure
)(SalesViewInner);
