import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MAIL from '../../img/ICON/mail.svg';
import USER from '../../img/ICON/user.svg';

import LayoutWrapper from '../Layout/LayoutWrapper';

const Slogan = styled.div`
  max-width: 500px;
  font-size: 16px;
  margin: auto;
  padding-top: 40px;
  text-align:left;
  @media (max-width: 700px) {
    padding-left:45px;
    padding-right:45px;
  }
`;

const Main = styled.div`
  width: 324px;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  margin: auto;
`;

const ActionIcon = styled.div`
  width: 137px;
  height: 137px;
  border-radius: 50%;
  background-color: #000000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.25s ease-out;

  &:hover {
    background: ${props => props.theme.red};
  }
`;

const Icon = styled.img``;

const Text = styled.div``;

const Contact = ({ store }) => (
  <LayoutWrapper
    hasTop
    height="563px"
    title="聯絡我們"
    engTitle="Contact"
    store={store}
    bg_url="../images/bg/bg1.jpg"
  >
    <Slogan>感謝大家對聖得福的愛護及支持，歡迎大家來信提出任何相關問題。<br/>聖得福建設團隊將竭盡所能地回覆您的疑問。謝謝您！</Slogan>
    <Main>
      <Link to="/contact">
        <ActionIcon>
          <Icon src={MAIL} />
          <Text>聯絡我們</Text>
        </ActionIcon>
      </Link>
      <Link to="/jobs">
        <ActionIcon>
          <Icon src={USER} />
          <Text>徵才資訊</Text>
        </ActionIcon>
      </Link>
    </Main>
  </LayoutWrapper>
);

export default Contact;
