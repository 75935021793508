import React, { PureComponent } from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";

import LayoutWrapper from "./Layout/LayoutWrapper";

import "../Scss/Editor.css";

const Content = styled.div`
  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 32px;
  position: relative;
  box-sizing: border-box;
`;

const PanelWrapper = styled.div`
  width: 100%;
  text-align: left;
  padding-bottom: 123px;
`;

const LeftPanel = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 37%;
  box-sizing: border-box;
  margin-left: 11.6vw;
  margin-right: 4.6vw;
  height: auto;
  h1 {
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 30px;
  }
  h5 {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
  @media (max-width: 700px) {
    h1 {
      font-weight: 600;
      padding-top: 20px;
      padding-bottom: 10px;
      font-size: 25px;
    }
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const RightPanel = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 35.9%;
  box-sizing: border-box;
  height: auto;
  @media (max-width: 700px) {
    background-size: auto 100%;
    background-repeat: no-repeat;
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const ButtomPanel = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 76%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  margin: 50px auto auto 12%;
  background: #FCFCFC;
  box-sizing: border-box;
  height: auto;
  padding: 0 66px 18px 66px;
  h1 {
    text-align: center;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 3px;
    font-size: 27px;
  }
  h5 {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
  @media (max-width: 700px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const ImageBox1 = styled.div`
  position: relative;
  width: 35.9vw;
  height: 35.9vw;
  background-repeat: no-repeat;
  background-image: url("${props => props.img_url}");
  background-position: center;
  background-size: cover;
  z-index: -1;
  margin-bottom:20px;
  margin-top:50px;
  @media (max-width: 700px) {
    width: 100%;
    height: 100%;
    margin:0;
    padding:0;
  }
`;

const MobilePicPanel = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  height: 400px;
  margin: 35px auto -10px 10%;
  @media (min-width: 700px) {
    width: 100%;
    height: 100%;
    margin:0;
    padding:0;
  }
`;

const ImageBox1Mobile = styled.div`
  position: relative;
  width: 80%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url("${props => props.img_url}");
  background-position: center;
  background-size: cover;
  z-index: -1;
`;

const DSpace = styled.div`
  position: ralative;
  height: 5px;
  min-width: 40px;
`;

const DBar = styled.div`
  position: ralative;
  width: 110px;
  height: 2px;
  background-color: black;
  min-width: 40px;
  margin: 0 auto;
  margin-bottom: 18px;
`;

const DTagWrapper = styled.div`
  margin-bottom: 18px;
  text-align: center;   
  display:none;

`

const DTag = styled.div`
  display: inline-block;
  vertical-align: top;
  position: relative;
  border: 1px solid black;
  width: 120px;
  height: 48px;
  line-height: 48px;
  box-sizing: border-box;
  text-align: center;
  margin: auto 15px;
  font-size: 20px;
  font-weight: 500;
  @media (max-width: 700px) {
    margin: auto 3px;
    width: 106px;
    height: 48px;
  }
`;


class AboutV2 extends PureComponent {
  render() {
    const { about, linkResolver, store } = this.props;
    const leftParagraph = about.data.about_group.splice(0, 2);;
    const buttomParagraph = about.data.about_group;
    return (
      <LayoutWrapper
        backgroundColor="rgb(255,255,255)"
        bg_opacity="0.9"
        height="auto"
        store={store}
        title="關於我們"
        bg_url="../images/bg/bg9.jpg"
        engTitle="About"
        hasTop
      >
        <PanelWrapper>
          <MobilePicPanel>
            <ImageBox1Mobile img_url="../images/cover/bg_inner_7.jpg" />
          </MobilePicPanel>
          <LeftPanel>
            {" "}
            {leftParagraph.map(d => (
              <Content>
                {RichText.render(d.title, linkResolver)}
                <DSpace />
                {RichText.render(d.content, linkResolver)}
              </Content>
            ))}{" "}
          </LeftPanel>
          <RightPanel>
            <ImageBox1 img_url="../images/cover/bg_inner_7.jpg" />
          </RightPanel>
          <ButtomPanel>
            {" "}
            {buttomParagraph.map(d => (
              <Content>
                {RichText.render(d.title, linkResolver)}
                <DBar />
                <DTagWrapper>
                  <DTag>公開透明</DTag>
                  <DTag>讓利</DTag>
                  <DTag>感恩</DTag>
                </DTagWrapper>
                {RichText.render(d.content, linkResolver)}
              </Content>
            ))}{" "}
          </ButtomPanel>
        </PanelWrapper>
      </LayoutWrapper>
    );
  }
}

export default AboutV2;
