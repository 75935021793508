import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { CSSTransitionGroup } from 'react-transition-group';
import { compose, pure } from 'recompose';
import CardBase from '../Card';

const ViewWrapper = styled(CardBase)`
  padding: 35px 45px;
  margin: ${props => (props.hasMargin ? '20px 20px' : 0)};
  width: 280px;
  height: 370px;
  box-sizing: border-box;
  cursor: pointer;
  @media (max-width: 700px) {
    margin: 20px auto;
  }
`;

const BuldingTitle = styled.div`
  font-size: 26px;
  margin-top:15px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 300;
  white-space: pre-line;
  line-height: 30px ;
`;

const Image = styled.img`
  width: 190px;
  height: 225px;
  object-fit: cover;
`;

const ViewCard = ({ building, hasMargin, to }) => (
  <Link to={to || `buildingview/${building.uid}`}>
    <ViewWrapper hasMargin={hasMargin}>
      {building.data.album[0].photo.url && (
        <Image src={building.data.album[0].photo.url} alt="" />
      )}
      <BuldingTitle>{building.data.title}</BuldingTitle>
      <SubTitle>{building.data.subtitle}</SubTitle>
    </ViewWrapper>
  </Link>
);

export default compose(pure)(ViewCard);
