export default function scrollTo(element, to, duration) {
  const start = element.scrollTop
  const change = to - start
  let currentTime = 0
  const increment = 20;

  const animateScroll = () => {
      currentTime += increment;
      const val = Math.easeInOutQuad(currentTime, start, change, duration);
      // eslint-disable-next-line
      element.scrollTop = val;
      if(currentTime < duration) {
          setTimeout(animateScroll, increment);
      }
  };
  animateScroll();
}

// t = current time
// b = start value
// c = change in value
// d = duration
Math.easeInOutQuad =  (t, b, c, d) => {
// eslint-disable-next-line
t /= d/2;
if (t < 1) return c/2*t*t + b;
// eslint-disable-next-line
t--;
return -c/2 * (t*(t-2) - 1) + b;
};
