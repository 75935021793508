import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { sortDate } from "./utils";
import CardBase from "../Card";
import { Date } from "prismic-dom";
import { format } from "date-fns";

const ViewWrapper = styled(CardBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  text-align: center;
  margin: ${props => (props.hasMargin ? "20px 20px 35px" : "auto")};
  width: 430px;
  height: 320px;
  box-sizing: border-box;
  cursor: pointer;
  @media (max-width: 700px) {
    width: 90%;
    margin-top: 40px;
  }
`;

const InfoWrapper = styled.div`
  max-width: 140px;
  @media (max-width: 700px) {
    margin-left: 20px;
  }
`;

const BuldingTitle = styled.div`
  font-size: 26px;
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 300;
`;

const Divider = styled.div``;

const Time = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
`;

const Image = styled.img`
  width: 200px;
  height: 240px;
  object-fit: cover;
  @media (max-width: 700px) {
    display: block;
    width: 150px;
  }
`;

class ProgressCard extends Component {
  state = {
    building: {
      ...this.props.building,
      data: {
        ...this.props.building.data,
        detail: sortDate(this.props.building.data.detail)
      }
    }
  };

  render() {
    const { building } = this.state;
    console.log("!XXCVB");
    //debugger;
    return (
      <Link to={`progress/${building.uid}`}>
        <ViewWrapper hasMargin={this.props.hasMargin}>
          {building.data.hero.url && <Image src={building.data.hero.url} />}
          <InfoWrapper>
            <BuldingTitle>{building.title || String(building.uid).toUpperCase()}</BuldingTitle>
            <Divider />
            {building.data.detail[building.data.detail.length - 1].text && (
              <SubTitle>{building.data.detail[building.data.detail.length - 1].text}</SubTitle>
            )}
            {building.data.detail[building.data.detail.length - 1].date && (
              <Time>{format(new Date(building.last_publication_date), "YYYY/MM/DD")} 更新</Time>
            )}
          </InfoWrapper>
        </ViewWrapper>
      </Link>
    );
  }
}

export default ProgressCard;
