import React from 'react';
import styled from 'styled-components';
import { Route, Link } from 'react-router-dom';

import LayoutWrapper from '../Layout/LayoutWrapper';
import JobInner from './JobInner';
import JobCard from './JobCard';
import JobApply from './JobApply';

const Content = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1200px;
  text-align: left;
  padding-left: 0;
  font-size: 0;

  @media (max-width: 1000px) {
    text-align: center;
  }
`;


const JobView = ({jobs, ...props}) => {
  const { linkResolver } = props.prismicCtx
  const getJob = match => {
    const result = jobs.results.filter(p => p.uid === match.params.id);
    return result[0];
  };
  const getAllJobTitle = () => {
    let titleList = [];
    if (jobs.results_size > 0) {
      titleList = jobs.results.map(job => ({
        value : job.uid,
        label: job.uid,
      }));
    }
    return titleList;
  }
  return (
    <LayoutWrapper
      title="徵才資訊"
      engTitle="Jobs"
      scroll
      hasTop
      absolute
      url="../images/banner/Contact_Banner.png"
      {...props}
    >
      {props.match.isExact && (
        <Content>
          {jobs.results.map(job => (
            <Link to={`/jobs/${job.uid}`} key={job.id}>
              <JobCard linkResolver={linkResolver} job={job} />
            </Link>
          ))}
        </Content>
      )}
      <Route
        path="/jobs/apply"
        render={routeProps => (
          <JobApply
            jobTitleList={getAllJobTitle()}
            linkResolver={linkResolver}
            {...routeProps}
          />
        )}
      />
      <Route
        path="/jobs/:id"
        render={routeProps => (
          <JobInner
            job={getJob(routeProps.match)}
            linkResolver={linkResolver}
            {...routeProps}
          />
        )}
      />
    </LayoutWrapper>
  )
}

export default JobView;
