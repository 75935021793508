// @flow
import React from "react";
import styled from "styled-components";
// import THREE from "./three_bundle";
// import Detector from "../../three_js_pagages/Detector";
import InnerWrapper from "./InnerWrapper";
// let container, clock, controls,stats;
// let camera, scene, renderer, mixer;
const Tr = styled.tr``;
const Th = styled.th`
  border-bottom: 1px solid black;
  color: #000000;
  font-size: 18px;
  text-align: left;
`;
const Td = styled.td`
  width: 100%;
  padding-top: 22px;
`;
const ThreeDWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 21vw;
  cursor: pointer;
  background-image: url("../images/3Dbackground.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.52);
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    transition: 0.35s background-color ease-in-out;
  }
  &:hover {
    &:before {
      background-color: rgba(0, 0, 0, 0.5);
    }
    div {
      transform: translateX(-50%) translateY(-50%) scale(1.5);
    }
  }
  @media (max-width: 700px) {
    height: 28vh;
  }
`;
const ThreeDButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 75px;
  height: 75px;
  background-image: url("../images/3Dbutton.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 25%;
  transition: 0.3s transform ease-in-out;
`;
const ThreeColser = styled.div`
  position: absolute;
  right: 40px;
  top: 80px;
  height: 24px;
  width: 24px;
  font-size: 24px;
  background-color: black;
  color: white;
  cursor: pointer;
`;
function Init3D(data, store, SetShow3D) {
  // debugger;
  // if (!Detector.webgl) Detector.addGetWebGLMessage();
  // init(data);
  // animate();
  //debugger;
  store.change3DUrl(data);
  store.changeIsRender3D(true);
  SetShow3D(true);
}
// function Destory3D(){
//   container.style.filter = 'blur(50px)';
//   container.style.opacity = '0';
//   cancelAnimationFrame(animate);
//   renderer.domElement.addEventListener('dblclick', null, false);
//   scene = null;
//   camera = null;
//   controls = null;
//   //clock = null;
//   empty(container);
//   setTimeout(function(){ container.style['z-index'] = '-1';}, 1500);

// }
// function init(data) {

//   container = document.getElementById('3d-container');
//   container.style['z-index'] = '10000';
//   container.style.opacity = 1;
//   camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 1, 10000);
//   camera.position.set(350, 250, -150);

//   scene = new THREE.Scene();

//   clock = new THREE.Clock();

//   // collada
//   // debugger;
//   let loader = new THREE.ColladaLoader();
//   loader.load(data.data, (collada) => {
//     let animations = collada.animations;
//     let avatar = collada.scene;
//     // debugger;
//     scene.add(avatar);

//     container.style.filter = 'blur(0px)';
//   });
//   // debugger;
//   //

//   let gridHelper = new THREE.GridHelper(100, 200);
//   scene.add(gridHelper);

//   //

//   let ambientLight = new THREE.AmbientLight(0xffffff, 300);
//   //scene.add(ambientLight);

//   // let directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
//   // // directionalLight.position.set(150, 150, - 150);
//   // // scene.add(directionalLight);

//   //
//   // var light2 = new THREE.DirectionalLight( 0xffffff, 1000 );
//   // light.position.set(, 250, -150);
//   // scene.add( light2 );

//   var light = new THREE.DirectionalLight( 0xffffff, 1000 );
//   light.position.set(350, 250, -150);
//   scene.add( light );

//   renderer = new THREE.WebGLRenderer({ antialias: true });
//   renderer.setPixelRatio(window.devicePixelRatio);
//   renderer.setSize(window.innerWidth, window.innerHeight);
//   container.appendChild(renderer.domElement);

//   //

//   controls = new THREE.OrbitControls(camera, renderer.domElement);
//   controls.target.set(0, 2, 0);
//   controls.update();

//   //

//   window.addEventListener('resize', onWindowResize, false);

// }

// function onWindowResize() {
//   camera.aspect = window.innerWidth / window.innerHeight;
//   camera.updateProjectionMatrix();

//   renderer.setSize(window.innerWidth, window.innerHeight);
// }

// function animate() {
//   requestAnimationFrame(animate);

//   render();
// }
// function render() {
//   var delta = clock.getDelta();

//   if (mixer !== undefined) {
//     mixer.update(delta);
//   }

//   renderer.render(scene, camera);
// }
// function empty(elem) {
//   elem.removeChild(elem.lastChild);
// }
const Inner3D = ({ data, store, SetShow3D, ...props }) => {
  console.log(store);
  debugger;
  return (
    <InnerWrapper>
      <Tr>
        <Th>點擊查看3D立體圖</Th>
      </Tr>
      <Tr>
        <Td>
          <ThreeDWrapper onClick={() => Init3D(data, store, SetShow3D)}>
            {/* <div id="container" style={{position:'fixed',top:'0',left:'0','zIndex':'100',opacity:0, transition:'0.45s opacity ease-in-out,1.5s filter ease-in-out 0.45s', filter:'blur(50px)'}} >
            <ThreeColser onClick={()=>Destory3D()}>X</ThreeColser>
          </div> */}
            <ThreeDButton />
          </ThreeDWrapper>
        </Td>
      </Tr>
    </InnerWrapper>
  );
};

export default Inner3D;
