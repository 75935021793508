import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';

import LayoutWrapper from '../Layout/LayoutWrapper';
import BuildingViewInner from './BuildingViewInner';
import InnerSideBar from '../InnerComponents/InnerSideBar';
import ViewCard from './ViewCard';
import { SELECT } from '../../utils/constants';

const DEFAULT_OPEN = {
  all: false,
  invest: false,
  years: false,
  sales: false
};

const MenuWrapper = styled.div`
  font-family: Roboto;
  font-size: 17px;
  letter-spacing: 4.2px;
  padding-top: 20px;
`;

const Option = styled.span`
  font-weight: ${props => (props.active ? '600' : '300')};
  cursor: pointer;
  transition: 0.25s all ease-in-out;

  &:hover {
    color: #979797;

    span {
      color: ${props => props.theme.black};
    }
  }

  &:not(:first-child):before {
    content: '/';
    padding: 4.2px;
    font-weight: 300;
  }
`;

const Content = styled.ul`
  margin: 0 auto;
  max-width: 1050px;
  padding-left: 0;
  font-size: 0;
  text-align: left;

  @media (max-width: 1050px) {
    text-align: center;
  }
`;

const ListItem = styled.li`
  display: inline-block;
  margin: 35px;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
`;

class BuldingViewer extends PureComponent {
  state = {
    view: 'all',
    list: null,
    sideBarList: null,
    current: null,
  };

  componentWillMount() {
    const invest = [];
    const investSimple = [];
    const years = [];
    const yearsSimple = [];
    const sales = [];
    const salesSimple = [];
    this.props.buildingView.results.forEach(building => {
      if (building.data.classification === '投資興建') {
        invest.push(building);
        investSimple.push({
          title: building.data.title,
          id: building.id,
          uid: building.uid,
          classification: 'invest'
        });
      } else if (building.data.classification === '歷年建案') {
        years.push(building);
        yearsSimple.push({
          title: building.data.title,
          id: building.id,
          uid: building.uid,
          classification: 'years'
        });
      } else if (building.data.classification === '熱銷推案') {
        sales.push(building);
        salesSimple.push({
          title: building.data.title,
          id: building.id,
          uid: building.uid,
          classification: 'sales'
        });
      }
    });

    const list = {
      all: this.props.buildingView.results,
      sales,
      years,
      invest,
    };

    const sideBarList = {
      all: this.props.buildingView.results.map(building => ({
        title: building.data.title,
        id: building.id,
        uid: building.uid
      })),
      sales: salesSimple,
      years: yearsSimple,
      invest: investSimple,
    };

    this.setState({
      list,
      sideBarList
    });
  }

  getBuilding = match => {
    const result = this.props.buildingView.results.filter(
      building => building.uid === match.params.id
    );
    return result[0];
  };

  selectView = key => {
    this.setState({
      view: key
    });
  };

  render() {
    const { match,SetShow3D,store } = this.props;
    return (
      <LayoutWrapper
        hasTop
        title="建案實績"
        engTitle="Building View"
        scroll
        absolute
        url="../images/banner/Building_Banner.png"

        {...this.props}
        renderSideBar={
          !match.isExact && (
            <InnerSideBar
              select={SELECT}
              status={DEFAULT_OPEN}
              current={this.state.current}
              data={this.state.sideBarList}
              nested
            />
          )
        }
      >
        {match.isExact && (
          <Fragment>
            <MenuWrapper>
              {Object.keys(SELECT).map(key => (
                <Option
                  onClick={() => this.selectView(key)}
                  key={key}
                  active={key === this.state.view}
                >
                  {SELECT[key]}
                </Option>
              ))}
            </MenuWrapper>
            <Content>
              {this.state.list[this.state.view].map(building => (
                <ListItem key={building.id}>
                  {/* <Link to={`${match.url}/${building.uid}`} key={building.id}> */}
                  <ViewCard building={building} />
                  {/* </Link> */}
                </ListItem>
              ))}
            </Content>
          </Fragment>
        )}
        <Route
          path="/buildingview/:id"
          render={routeProps => (
            <BuildingViewInner
              SetShow3D={SetShow3D}
              store={store}
              {...routeProps}
              currentId={(uid) => {
                this.setState({
                  current: uid
                })
              }}
              building={this.getBuilding(routeProps.match)}
              prismicCtx={this.props.prismicCtx}
            />
          )}
        />
      </LayoutWrapper>
    );
  }
}

export default BuldingViewer;
