import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Waypoint from "react-waypoint";
import styled from 'styled-components';
import "./style.css";
import useWindowSize from '../../hooks/useWIndowSize'

// const images = [
//   "https://d33wubrfki0l68.cloudfront.net/dd23708ebc4053551bb33e18b7174e73b6e1710b/dea24/static/images/wallpapers/shared-colors@2x.png",
//   "https://d33wubrfki0l68.cloudfront.net/49de349d12db851952c5556f3c637ca772745316/cfc56/static/images/wallpapers/bridge-02@2x.png",
//   "https://d33wubrfki0l68.cloudfront.net/594de66469079c21fc54c14db0591305a1198dd6/3f4b1/static/images/wallpapers/bridge-01@2x.png"
// ];

export const ImageBox = styled(motion.div)`
  position:absolute;
  width:100%;
  height:100%;
  background-image:url('${({src})=>src}');
  background-position: center center;
  background-size: 100% auto;
   &::before {
    width:100%;
    height:100%;
    content:'';
    position:absolute;
    top:0;
    left:0;
    z-index:3;
    background:linear-gradient(270deg, rgba(0, 0, 0, 0) 17.3%, rgba(0, 0, 0, 0) 100%);
  }
  &.has-mask {
    &::before {
      background:linear-gradient(270deg, rgba(0, 0, 0, 0) 17.3%, rgba(0, 0, 0, 0.3) 100%);
    }
  }
`

export const ImageWrapper = styled.div`
  width: 100vw; 
  height: 59.3vw;
  position: relative;
  @media (max-width: 700px) {
    margin-top:25px;
    width: 100vw;
    height: 177vw;
  }
`

const SloganWrapper = styled.div`
  position: absolute;
  width:100%;
  text-align:center;
  z-index:5;
  top:50%;
  left:0;
  transform:translateY(-50%);
  box-sizing:border-box;
  padding: 0px 10%;
  &.is-left{
    text-align:left;
  }
  @media (max-width: 700px) {
    padding: 0 60px;
    top:35%;
    &.is-left{
      text-align:center;
    }
  }
`

const MainSlogan = styled.div`
  font-weight: 600;
  font-size: 60px;
  line-height: 60px;
  color: #FFFFFF;
  margin-bottom:25px;
  @media (max-width: 700px) {
    margin-bottom:16px;
    font-size: 50px;
  }
`
const SecondarySlogan = styled.div`
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  color: #FFFFFF;
  @media (max-width: 700px) {
    font-size: 20px;
  }
`

const variants = {
  enter: (direction) =>
  ({
    x: direction > 0 ? 0 : -0,
    opacity: 0
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) =>
  ({
    zIndex: 0,
    x: direction < 0 ? 0 : -0,
    opacity: 0
  })
};

let interval = 0;
/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

export default function Example(props) {
  const { width } = useWindowSize();
  const [[page, direction], setPage] = useState([0, 0]);
  const carrouselData = width > 700 ? props.info.data.carousel:props.info.data.mobile_carousel;
  const images = carrouselData.map(d => d.cover_image.url)
  const imageIndex = page % images.length;
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
  useEffect(() => {
    interval = setInterval(() => paginate(1), 5000)
    return () => { clearInterval(interval) }
  }, [paginate])


  return (
    <Waypoint onEnter={props.onEnter} onLeave={props.onLeave}>
      <ImageWrapper>
        <AnimatePresence initial={false} custom={direction}>
          <ImageBox
            key={`imageBox${page}`}
            className={carrouselData[imageIndex].mask && "has-mask"}
            src={images[imageIndex]}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.6 }
            }}
            // drag="x"
            // dragConstraints={{ left: 0, right: 0 }}
            // dragElastic={1}
            // onDragEnd={(e, { offset, velocity }) => {
            //   const swipe = swipePower(offset.x, velocity.x);

            //   if (swipe < -swipeConfidenceThreshold) {
            //     paginate(1);
            //   } else if (swipe > swipeConfidenceThreshold) {
            //     paginate(-1);
            //   }
            // }}
          >
            <SloganWrapper className={props.info.data.carousel[imageIndex].title_position % 3 === 1 && "is-left"}>
              <MainSlogan>{carrouselData[imageIndex].first_slogan}</MainSlogan>
              <SecondarySlogan>{carrouselData[imageIndex].second_slogan}</SecondarySlogan>
            </SloganWrapper>
          </ImageBox>
        </AnimatePresence>
      </ImageWrapper>
    </Waypoint>
  );
};
