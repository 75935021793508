import React from "react";
import styled from "styled-components";
import {
  // Link,
  Route,
} from "react-router-dom";

import LayoutWrapper from "../Layout/LayoutWrapper";
import ProgressCard from "./ProgressCard";
import ProjectProgressInner from "./ProjectProgressInner";
import InnerSideBar from "../InnerComponents/InnerSideBar";

const Content = styled.ul`
  margin: 0 auto;
  max-width: 1000px;
  text-align: left;
  padding-left: 0;
  font-size: 0;

  @media (max-width: 1000px) {
    text-align: center;
  }
`;

const ListItem = styled.li`
  display: inline-block;
  margin: 35px;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
`;

const ProjectProgressView = (props) => {
  const getProgress = (match) => {
    const result = props.progress.results.filter((p) => p.uid === match.params.id);
    return result[0];
  };
  return (
    <LayoutWrapper
      title="工程進度"
      engTitle="Project Progress"
      scroll
      hasTop
      absolute
      url="../images/banner/ProjectProgress_Banner3.jpg"
      {...props}
      renderSideBar={!props.match.isExact && <InnerSideBar data={props.progress.results} />}
    >
      {props.match.isExact && (
        <Content>
          {props.progress.results.map((s) => (
            <ListItem key={s.id}>
              <ProgressCard building={s} />
            </ListItem>
          ))}
        </Content>
      )}
      <Route
        path="/progress/:id"
        render={(routeProps) => <ProjectProgressInner progress={getProgress(routeProps.match)} {...routeProps} />}
      />
    </LayoutWrapper>
  );
};

export default ProjectProgressView;
