// @flow
import React from 'react';
import styled from 'styled-components';
import { differenceInYears } from 'date-fns';
import { Date } from 'prismic-dom';

import InnerWrapper from './InnerWrapper';

const Tr = styled.tr``;
const Th = styled.th`
  border-bottom: 1px solid black;
  color: #000000;
  font-size: 18px;
  text-align: left;
`;
const Td = styled.td`
  padding-top: 11px;
  width: 40%;
  color: #000000;
  font-size: 14px;
  text-align: left;
`;

const map = {
  info: '基本記事',
  importantDate: '重要記事'
};

const infoMap = {
  city: '所在縣/市',
  district: '鄉鎮市區',
  area: '計劃範圍',
  zone: '使用分區',
  size: '計劃面積',
  floor: '樓層規模',
  executor: '實施者',
  design: '建築設計',
  planner: '都更規劃',
  status: '辦理情形',
  building_position: '基地位置',
  all_around_plan:'整體規劃',
  building_design:'建築設計',
  buliding_looking:'建築外觀',
  architecture_design:'結構設計',
  gardan_looking:'庭園景觀',
  amenities:'門廳公設',
  lighting_design:'燈光設計',
  finish_time:'完工時間'
};

function checkIsValueEmpty(data) {
  let hasValueCount = 0;
  Object.keys(data).forEach(key => {
    if (data[key] != null) {
      hasValueCount += 1;
    }
  });
  if (hasValueCount === 0) {
    return true;
  }
  return false;
}

function checkIsBothEmpty(data) {
  let hasValueCount = 0;
  data.forEach(d => {
    if (!checkIsValueEmpty(d)) {
      hasValueCount += 1;
    }
  });

  if (hasValueCount === 0) {
    return true;
  }
  return false;
}

const InnerTable = ({ data }) => {
  const oldDate = new Date(1911);
  oldDate.setFullYear(1911);
  const { primary, items } = data;
  if (checkIsValueEmpty(primary) && checkIsBothEmpty(items)) {
    return null;
  }
  const getTWDate = date => {
    const newDate = Date(date);
    return `${differenceInYears(newDate, oldDate)}/${newDate.getMonth() +
      1}/${newDate.getDate()}`;
  };

  if (data.slice_type === 'info') {
    return (
      <InnerWrapper>
        <Tr>
          <Th colSpan={2}>{map[data.slice_type]}</Th>
        </Tr>
        {Object.keys(primary).map(key => {
          if (primary[key] === null) {
            return null;
          }
          return (
            <Tr key={key}>
              <Td>{infoMap[key]}</Td>
              <Td>{primary[key]}</Td>
            </Tr>
          );
        })}
        {items.map(item => {
          if (item.value) {
            return (
              <Tr key={item.key}>
                <Td>{item.key}</Td>
                <Td>{item.value}</Td>
              </Tr>
            );
          }
          return null;
        })}
      </InnerWrapper>
    );
  }
  return (
    <InnerWrapper>
      <Tr>
        <Th colSpan={2}>{map[data.slice_type]}</Th>
      </Tr>
      {Object.keys(primary).map(key => (
        <Tr key={key}>
          <Td>{key}</Td>
          <Td>{primary[key]}</Td>
        </Tr>
      ))}
      {items.map((item, i) => {
        if (item.important_text) {
          return (
            <Tr key={i}>
              <Td>
                {getTWDate(item.date_1)}
                {item.date_2 && <span>~{getTWDate(item.date_2)}</span>}
              </Td>
              <Td>{item.important_text}</Td>
            </Tr>
          );
        }
        return null;
      })}
    </InnerWrapper>
  );
};

export default InnerTable;
