// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import PrismicApp from './PrismicApp';
import './index.css';
import Colors from './utils/colors';


ReactDOM.render(
  <ThemeProvider theme={Colors}>
    <PrismicApp />
  </ThemeProvider>,
  document.getElementById('root'),
);
