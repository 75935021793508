import React from "react";
import styled from "styled-components";
import { pure, compose, lifecycle } from "recompose";

// import InnerSideBar from '../InnerComponents/InnerSideBar';
import InnerSlideAlbum from "../InnerComponents/InnerSlideAlbum";
import InnerSubTitle from "../InnerComponents/InnerSubTitle";
import InnerTable from "../InnerComponents/InnerTable";
import Inner3D from "../InnerComponents/Inner3D";
import InnerYoutube from "../InnerComponents/InnerYoutube";
import LayoutBlock from "../Layout/LayoutBlock";

import "../../Scss/Editor.css";

const Root = styled.div`
  ${"" /* height: 100%; */} position: relative;
  ${"" /* width: 100%; */};
`;
const MainPanel = styled.div`
  ${"" /* flex: 1; */} ${"" /* bottom: 0px;
  position: absolute;
  left: 300px;
  right: 90px;
  top: 0px;
  overflow: auto; */};
`;

const BuildingViewInner = ({
  building,
  // sideBarList,
  linkResolver,
  store,
  SetShow3D
  // currentId,
  // ...props
}) => {
  if (building) {
    const { data } = building;
    //debugger;
    return (
      <Root>
        {/* <InnerSideBar data={sideBarList} current={building} nested /> */}
        <MainPanel className="MainPanel">
          {/* <InnerTitle /> */}

          <LayoutBlock is_building>
            <InnerSubTitle title={data.title} desc={data.desc} is_building linkResolver={linkResolver} />
            <InnerSlideAlbum album={data.album} is_building class="is-inner" />
            {data.body.map((table, i) => (
              <InnerTable key={i} data={table} />
            ))}
            {data["3d"].url && <Inner3D data={data["3d"].url} store={store} SetShow3D={SetShow3D} />}
            {data.movie.url && <InnerYoutube movie={data.movie} />}
          </LayoutBlock>
        </MainPanel>
      </Root>
    );
  }
  return <Root>Not Found</Root>;
};

export default compose(
  lifecycle({
    componentDidMount() {
      if (this.props.currentId) {
        this.props.currentId(this.props.building.uid);
      }
    }
  }),
  pure
)(BuildingViewInner);
