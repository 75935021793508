import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Date } from "prismic-reactjs";
import { format } from "date-fns";
import { sortDate } from "./DateSort";

const ListItem = styled.li`
  width: 35vw;
  min-width: 322px;
  height: 52px;
  line-height: 52px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding-bottom: 20px;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  transition: all 0.25s ease-in;

  &:hover {
    opacity: 0.6;
  }
`;
const Title = styled.div`
  display: inline-block;
  font-size: 18px;
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  @media (max-width: 700px) {
    max-width: 300px;
  }
`;

const Dash = styled.div`
  position: relative;
  flex: 1;
  border-top: 1px solid #000;
  top: 17px;
  margin: 10px;
`;

const Time = styled.div`
  display: inline-block;
  font-size: 16px;
  font-weight: lighter;
  font-style: italic;
`;

const NewsListItems = ({ newsList }) =>
  sortDate(newsList).map((item, index) => {
    if (window.outerWidth <= 700) {
      if (index < 4) {
        return (
          <Link key={item.id} to={`/news/${item.uid}`}>
            <ListItem>
              {item.data.title.charAt(0) === "「" && <Title style={{ marginLeft: "-10px" }}>{item.data.title}</Title>}
              {item.data.title.charAt(0) !== "「" && <Title>{item.data.title}</Title>}
              <Time>
                {format(
                  new Date(item.data.publishdate ? item.data.publishdate : item.first_publication_date),
                  "YYYY/MM/DD"
                )}
              </Time>
            </ListItem>
          </Link>
        );
      }
    } else if (index < 8) {
      return (
        <Link key={item.id} to={`/news/${item.uid}`}>
          <ListItem>
            {item.data.title.charAt(0) === "「" && <Title style={{ marginLeft: "-10px" }}>{item.data.title}</Title>}
            {item.data.title.charAt(0) !== "「" && <Title>{item.data.title}</Title>}
            <Dash />
            <Time>
              {format(
                new Date(item.data.publishdate ? item.data.publishdate : item.first_publication_date),
                "YYYY/MM/DD"
              )}
            </Time>
          </ListItem>
        </Link>
      );
    }
  });

export default NewsListItems;
