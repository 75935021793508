import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { Wrapper, Field, Label, Input, TextArea, SubmitButton } from '../Form';

const Title = styled.h1`
  text-align: left;
`;

class JobApply extends PureComponent {
  state = {
    selectedOption: '',
    name: ''
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };

  onChangeName = e =>
    this.setState({
      name: e.target.value
    });

  render() {
    const { jobTitleList } = this.props;
    const { selectedOption, name } = this.state;
    const value = selectedOption && selectedOption.value;
    return (
      <div>
        <Wrapper
          action="https://formspree.io/bliss@sendfuel.com"
          method="POST"
        >
          <input type="hidden" name="_subject" value={`應徵[${value} - ${name}]`} />
          <Title>應徵</Title>
          <Field>
            <Label>你的姓名 Name*</Label>
            <Input
              type="text"
              name="Name"
              required
              onChange={this.onChangeName}
              autoComplete="name"
              value={name}
            />
          </Field>
          <Field>
            <Label>連絡電話 Phone*</Label>
            <Input type="text" name="phone" required  />
          </Field>
          <Field>
            <Label>連絡信箱 Email*</Label>
            <Input type="email" name="_replyto" required autoComplete="email" />
          </Field>
          <Field>
            <Label>希望應徵的職位 Position*</Label>
            <Select
              searchable={false}
              clearable={false}
              name="Position"
              value={value}
              required
              placeholder="請選擇"
              style={{
                borderRadius: 0,
                border: '1px solid #b9b9b9',
                height: '46px',
                fontSize: '16px'
              }}
              onChange={this.handleChange}
              options={jobTitleList}
            />
          </Field>
          <Field>
            <Label>自我介紹 Introduction*</Label>
            <TextArea name="Introduction" required />
          </Field>
          <SubmitButton>送出</SubmitButton>
        </Wrapper>
      </div>
    );
  }
}

export default JobApply;
