import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  flex: 2;
  display: inline-block;
  padding: 30px 0 0px 30px;
  height: auto;
`;

const BigPicture = styled.div`
  max-width: 600px;
  max-height: 428px;
`;

const Image = styled.img`
  width: 100%;
  max-height: 428px;
  object-fit: contain;
`;

const SmallPictureWrapper = styled.div`
  width: 100%;
  ${'' /* height: 95px; */}
  overflow: auto;
  ${'' /* white-space: nowrap; */}
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
`;
const SmallPicture = styled.div`
  width: 95px;
  height: 58px;
  margin-right: 20px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;
`;
const EditorWrapper = styled.div`
  display: inline-block;
  flex: 1;
  ${'' /* width: calc(100% - 680px);
  height: calc(100% - 165px); */} min-width: 210px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
`;

class UrbanContent extends PureComponent {
  state = {
    currentPhotoIndex: 0
  };

  onPictureChange = i => {
    this.setState({
      currentPhotoIndex: i
    });
  };

  render() {
    const { data, linkResovler } = this.props;
    if (data) {
      return (
        <Root>
          <ContentWrapper>
            {data.items[this.state.currentPhotoIndex].album2.url && (
              <BigPicture>
                <Image
                  src={data.items[this.state.currentPhotoIndex].album2.url}
                  alt=""
                />
              </BigPicture>
            )}
            <SmallPictureWrapper>
              {data.items.map(
                (item, i) =>
                  i !== this.state.currentPhotoIndex && (
                    <SmallPicture
                      key={i}
                      onClick={() => this.onPictureChange(i)}
                    >
                      <img
                        src={item.album2.url}
                        alt=""
                        style={{ width: '100%', height: '100%' }}
                      />
                    </SmallPicture>
                  )
              )}
            </SmallPictureWrapper>
          </ContentWrapper>
          <EditorWrapper className="urban">
            {data.primary.header &&
              <h1>{data.primary.header}</h1>}
            {data.primary.content &&
              RichText.render(data.primary.content, linkResovler)}
          </EditorWrapper>
        </Root>
      );
    }
    return null;
  }
}

export default UrbanContent;
