import React from 'react';
import styled from 'styled-components';

const Root = styled.div``;

const Loading = () => (
  <Root>Loading...</Root>
);

export default Loading;
