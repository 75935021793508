import React from "react";
import "whatwg-fetch";
import Prismic from "prismic-javascript";
import PrismicToolbar from "prismic-toolbar";
import PrismicConfig from "./prismic-configuration";
import App from "./App";
import Api from "./api/";
import LogoLoading from "./LogoLoading";

export default class PrismicApp extends React.Component {
  state = {
    prismicCtx: null,
    doc: null
  };

  componentWillMount() {
    this.buildContext()
      .then(prismicCtx => {
        this.setState({ prismicCtx });
      })
      .then(() => {
        this.getData();
      })
      .catch(e => {
        console.error(`Cannot contact the API, check your prismic configuration:\n${e}`);
      });
  }

  getData = async () => {
    //debugger;
    const res = await Api(this.state.prismicCtx);
    this.setState({
      doc: res
    });
    console.log(res, 'DATA');
  };

  refreshToolbar() {
    const maybeCurrentExperiment = this.api.currentExperiment();
    if (maybeCurrentExperiment) {
      PrismicToolbar.startExperiment(maybeCurrentExperiment.googleId());
    }
    PrismicToolbar.setup(PrismicConfig.apiEndpoint);
  }

  buildContext() {
    const { accessToken } = PrismicConfig;
    return Prismic.api(PrismicConfig.apiEndpoint, { accessToken }).then(api => ({
      api,
      endpoint: PrismicConfig.apiEndpoint,
      accessToken,
      linkResolver: PrismicConfig.linkResolver,
      toolbar: this.refreshToolbar
    }));
  }

  render() {
    if (this.state.doc) {
      //debugger;
      return <App prismicCtx={this.state.prismicCtx} data={this.state.doc} />;
    }
    return <LogoLoading />;
  }
}
