import styled from "styled-components";
import posed from "react-pose";
import { tween, easing } from "popmotion";

const NAV_PROPS = {
  hide: {
    top: "-100px",
    opacity: 0.9
  },
  showTop: {
    top: "30px",
    opacity: 0.9,
    left: "40px",
    right: "40px",
    transition: props => tween({ ...props, duration: 1000, ease: easing.backOut })
  },
  show: {
    top: 0,
    left: 0,
    right: 0,
    opacity: 1
  }
};

export const Root = styled(posed.div(NAV_PROPS))`
  z-index: 100;
  display: flex;
  height: 62px;
  background: ${props => props.theme.black};
  color: ${props => props.theme.white};
  justify-content: space-between;
  left: 40px;
  right: 40px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  padding: 11px 30px;
  padding-right: 0;
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 31px;
  height: 30px;
  color: ${props => props.theme.white};
`;

export const Name = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
`;

export const ChName = styled.div`
  font-size: 15px;
  line-height: 15px;
  color: white;
`;

export const EngName = styled.div`
  padding-top: 4px;
  font-size: 9px;
  line-height: 9px;
  color: white;
`;

export const NavList = styled.div`
  display: flex;
  font-size: 12px;
  padding: 20px;
  padding-left: 0;
`;

export const NavItem = styled.div`
  position: relative;
  margin-right: 25px;
  margin-left: 25px;
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.25s ease-in;
  font-size: 0.93vw;

  &:hover {
    color: rgba(255, 255, 255, 0.5);
  }

  &:not(:last-child):after {
    position: absolute;
    content: "";
    height: 100%;
    right: -25px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }

  &:last-child {
    margin-right: 0;
  }
  @media (min-width: 1441px) {
    font-size: 1.05vw;
  }
`;

export const Icon = styled.div`
  width: 35px;
  height: 35px;
  padding: 10px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
