import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import Parallax from "parallax-js";
const ShowIn = keyframes`
  from {
    opacity:0;
    filter:blur(10px);
  }

  to {
    opacity:1;
    filter:blur(0px);
  }
`;
const Root = styled.div`
  position: relative;
  width: 100vw;
  height: 28vw;
  z-index: 1;
  animation: ${ShowIn} 0.7s linear;
  animation-fill-mode: forwards;
  overflow:hidden;
`;
const BackgroundLayer = styled.div`
  position: relative;
  height: 110%;
  width: 110%;
  top:-5% !important;
  left:-5% !important;
  background-image: ${props => (props.url ? 'url("' + props.url + '")' : 'url("../images/banner1.png")')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;

`;
const EngTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.48px;
  line-height: 20px;
  padding-top: 5px;
  color: white;
`;
const Title = styled.div`
  font-size: 30px;
  letter-spacing: -0.72px;
  font-weight: 500;
  color: white;
`;

const TitleWrapper = styled.div`
  position: absolute;
  text-align: center;
  top: 45% !important;
  left: 50% !important;
  margin-top:-33.5px;
  margin-left:-58.5px;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
`;
const parabox = {
  width: "100%",
  height: "100%"
};
class InnerBanner extends PureComponent {
  componentDidMount() {
    const hi = document.getElementById("parawrapper");
    this.parallax = new Parallax(hi);
  }
  componentWillUnmount() {
    this.parallax.disable();
  }
  render() {
    const { url, title, engTitle } = this.props;
    return (
      <Root url={url} id="parawrapper">
        <BackgroundLayer url={url} data-Depth="0.2" />
        <TitleWrapper data-Depth="0.03" >
          <Title>{title}</Title>
          <EngTitle>{engTitle}</EngTitle>
        </TitleWrapper>
      </Root>
    );
  }
}

export default InnerBanner;
