import styled from 'styled-components';

const LayoutBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  position: relative;
  ${props => {
    if (props.is_building) {
      return `justify-content : center`;
    }
    return '';
  }};
  @media (max-width: 700px) {
    justify-content : center
  }
`;

export default LayoutBlock;
