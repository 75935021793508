import React from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import CardBase from '../Card';

const Root = styled(CardBase)`
  width: 320px;
  height: 250px;
  padding: 25px 50px;
  box-sizing: border-box;
  margin: 35px;
  position:relative;
  font-size:18px;
  font-weight:500;
  &:hover {
    &:before{
      color:rgba(0,0,0,0.8);
    }
  }
  &:before{
    content:'點擊以查看詳情';
    position:absolute;
    bottom:10px;
    left:50%;
    color:rgba(0,0,0,0.5);
    transform:translateX(-50%);
    transition: 0.25s color ease-in-out ;
  }
  
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 500;
`;

const Content = styled.p`
  font-size: 16px;
  margin: auto;
  letter-spacing: 0;
  text-align: left;
  padding-top: 10px;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const JobCard = ({ job, linkResolver }) => {
  const { data } = job;
  const allP = data.partialcontent;
  return (
    <Root>
      {data.title && <Title>{RichText.render(data.title, linkResolver)}</Title>}
      {data.partialcontent && (
        <Content>{RichText.render(data.partialcontent, linkResolver)}</Content>
      )}
    </Root>
  );
};

export default JobCard;
