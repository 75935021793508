import React, { PureComponent, Fragment } from "react";
import styled, { keyframes } from "styled-components";
import {
  BrowserRouter as Router,
  Route,
  Switch
  // Redirect
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ThreeDViewer from "./Components/InnerComponents/ThreeDViewer";
import Preview from "./Preview";
import Help from "./Help";
import Landingpage from "./Components/LandingPage";
import NotFound from "./NotFound";
import UrbanRenewalInner from "./Components/Urban/UrbanRenewalInner";
import BuildingViewer from "./Components/BuildingView/BuildingViewer";
import NavModel from "./Components/Nav/NavModel";
import Nav from "./Components/Nav/Nav";
import "./App.css";
import NewsView from "./Components/News/NewsView";
import ProjectProgressView from "./Components/ProjectProgress/ProjectProgressView";
import ContactInner from "./Components/Contact/ContactInner";
import JobView from "./Components/Job/JobView";
import SalesViewer from "./Components/Sales/SalesViewer";
import Api from "./Components/News/NewsApi";


const store = new NavModel();

const upAndDown = keyframes`
  0% {
    transform: translateY(0%); }
  50% {
    transform: translateY(-5%); }
  100% {
    transform: translateY(0%); } }
`;

const Oval = styled.div`
  width: 100%;
  background: url(images/Oval.svg);
  background-repeat: repeat-y;
  height: 463px;
  position: absolute;
  overflow: hidden;
`;

const OvalOne = styled(Oval)`
  background-position: 10%;
  animation: ${upAndDown} 4s infinite;
  top: -10%;
  opacity: ${props => {
    return props.store.isBackgroundOpacity ? "0.1" : "1";
  }};
`;
const OvalTwo = styled(Oval)`
  top: 60%;
  background-position-x: 90%;
  animation: ${upAndDown} 4s infinite 1s;
  opacity: ${props => (props.store.isBackgroundOpacity ? "0.1" : "1")};
`;
const boxStyle = {
  "background-color": "rgba(255,255,255,0.85)",
  position: "relative"
};
class App extends PureComponent {
  state = {
    data: this.props.data,
    is_show_3d: false,
    now_page_news: this.props.data.news
  };

  componentDidMount() {
    this.reorderBuildingView();
  }

  reorderBuildingView = () => {
    const results = this.props.data.buildingView.results.sort((a, b) => {
      let aYear = 0;
      let bYear = 0;
      if (a.data.body[0] && a.data.body[0].primary && a.data.body[0].primary.finish_time) {
        aYear = isNumber(a.data.body[0].primary.finish_time) ? a.data.body[0].primary.finish_time : 1;
      }
      if (b.data.body[0].primary && b.data.body[0].primary.finish_time) {
        bYear = isNumber(b.data.body[0].primary.finish_time) ? b.data.body[0].primary.finish_time : 1;
      }
      return bYear - aYear;
    });

    this.reorderByCate(results);
  };

  reorderByCate = data => {
    const invest = [];
    const years = [];
    const sales = [];
    data.forEach(building => {
      if (building.data.classification === "投資興建") {
        invest.push(building);
      } else if (building.data.classification === "歷年建案") {
        years.push(building);
      } else if (building.data.classification === "熱銷推案") {
        sales.push(building);
      }
    });

    const results = sales.concat(years.concat(invest));
    this.setState({
      data: {
        ...this.props.data,
        buildingView: {
          ...this.props.data.buildingView,
          results
        }
      }
    });
  };
  SetShow3D = value => {
    this.setState({ is_show_3d: value });
  };
  Render3D = (store) => {
    if (this.state.is_show_3d) {
      // show the modal if state showModal is true
      return <ThreeDViewer store={store} SetShow3D={this.SetShow3D} />;
    }
  };
  paggerchange = currentPage => {
    this.getData(currentPage);
  };
  getData = async pageIndex => {
    const res = await Api(this.props.prismicCtx, pageIndex);
    this.setState({ now_page_news: res.news });
  };
  render() {
    return (
      <Fragment>
        <OvalOne store={store} />
        <OvalTwo store={store} />
        <Router>
          <Route
            render={({ location, ...props }) => (
              <Fragment>
                {this.Render3D(store)}
                <Nav location={location} {...props} store={store} />
                <TransitionGroup style={boxStyle}>
                  <CSSTransition key={location.key} classNames="fade" timeout={1000}>
                    <Fragment>
                      <Switch location={location}>
                        {/* <Redirect exact from="/" to="/Landingpage" /> */}
                        <Route
                          exact
                          path="/"
                          render={() => (
                            <Landingpage
                              location={location}
                              store={store}
                              data={this.state.data}
                              prismicCtx={this.props.prismicCtx}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/preview"
                          render={routeProps => <Preview {...routeProps} prismicCtx={this.props.prismicCtx} />}
                        />
                        <Route
                          path="/news"
                          render={routeProps => (
                            <NewsView
                              store={store}
                              {...routeProps}
                              news={this.state.now_page_news}
                              prismicCtx={this.props.prismicCtx}
                              paggerchange={this.paggerchange}
                            />
                          )}
                        />
                        <Route
                          path="/buildingview"
                          render={routeProps => (
                            <BuildingViewer
                              store={store}
                              {...routeProps}
                              buildingView={this.state.data.buildingView}
                              prismicCtx={this.props.prismicCtx}
                              SetShow3D={this.SetShow3D}
                            />
                          )}
                        />
                        <Route
                          path="/sales"
                          render={routeProps => (
                            <SalesViewer
                              store={store}
                              {...routeProps}
                              sales={this.state.data.sales}
                              prismicCtx={this.props.prismicCtx}
                            />
                          )}
                        />
                        <Route
                          path="/progress"
                          render={routeProps => (
                            <ProjectProgressView
                              store={store}
                              progress={this.state.data.progress}
                              {...routeProps}
                              prismicCtx={this.props.prismicCtx}
                            />
                          )}
                        />
                        <Route
                          path="/contact"
                          render={routeProps => (
                            <ContactInner store={store} {...routeProps} prismicCtx={this.props.prismicCtx} />
                          )}
                        />
                        <Route
                          path="/jobs"
                          render={routeProps => (
                            <JobView
                              jobs={this.state.data.jobs}
                              store={store}
                              {...routeProps}
                              prismicCtx={this.props.prismicCtx}
                            />
                          )}
                        />
                        <Route
                          path="/urbanrenewal/:id"
                          render={routeProps => (
                            <UrbanRenewalInner
                              store={store}
                              {...routeProps}
                              urban={this.state.data.urban}
                              prismicCtx={this.props.prismicCtx}
                              SetShow3D={this.SetShow3D}
                            />
                          )}
                        />
                        <Route path="/help" component={Help} />
                        <Route component={NotFound} />
                      </Switch>
                    </Fragment>
                  </CSSTransition>
                </TransitionGroup>
              </Fragment>
            )}
          />
        </Router>
      </Fragment>
    );
  }
}

export default App;

function isNumber(n) {
  // eslint-disable-next-line
  return !isNaN(parseFloat(n)) && isFinite(n);
}
