import React, { PureComponent } from "react";
import styled from "styled-components";
import Waypoint from "react-waypoint";
import NewsListItem from "./NewsListItem";
import LayoutWrapper from "../Layout/LayoutWrapper";

const ListWrapper = styled.ul`
  padding-top: 30px;
  padding-bottom: 60px;
  
  overflow: hidden;
`;

class News extends PureComponent {
  handleWaypointEnter(){
    console.log('news-enter');
  }
  handleWaypointLeave(){
    console.log('news-leave');
  }
  render() {
    const { news, store } = this.props;
    return (

        <LayoutWrapper hasTop title="最新消息" engTitle="News" hasMore="/news" store={store} bg_url="../images/bg/bg2.jpg">
          <Waypoint onEnter={this.handleWaypointEnter} onLeave={this.handleWaypointLeave}>
          </Waypoint>
          <ListWrapper>
            <NewsListItem newsList={news.results} />
          </ListWrapper>
        </LayoutWrapper>

    );
  }
}

export default News;
