import Prismic from 'prismic-javascript';

export default async function getPrismicContext(ctx) {
  const api = await Prismic.api(ctx.endpoint, {
    accessToken: ctx.accessToken,
  });
  console.log(api);
  // const response = await api.query(Prismic.Predicates.at('document.type', 'cover'));
  const response = {};
  response.cover = await api.getSingle('cover');
  response.coverV2 = await api.getSingle('cover_v2');
  response.about = await api.getSingle('about');
  response.news = await api.query(Prismic.Predicates.at('document.type', 'news'), { pageSize: 10, page: 1 });
  response.buildingView = await api.query(Prismic.Predicates.at('document.type', 'building_view'));
  response.urban = await api.query(Prismic.Predicates.at('document.type', 'urban'), { pageSize: 100 });
  response.progress = await api.query(Prismic.Predicates.at('document.type', 'progress'));
  response.sales = await api.query(Prismic.Predicates.at('document.type', 'sales'));
  response.jobs = await api.query(Prismic.Predicates.at('document.type', 'jobs'));
  console.log(response);
  return response;
}
