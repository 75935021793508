import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  padding-top: 70px;
  height: 100px;
  line-height: 7;
  font-size: 12px;
`;

const InnerFooter = () => (
  <Root>Copyright © 2017 聖得福建設開發股份有限公司</Root>
);

export default InnerFooter;
