// @flow
import React, { PureComponent } from "react";
import styled from "styled-components";
import THREE from "./three_bundle";
import Detector from "../../three_js_pagages/Detector";

let container, clock, controls, stats;
let camera, scene, renderer, mixer, ani;

const ThreeColser = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  height: 24px;
  width: 24px;
  font-size: 24px;
  background-color: black;
  color: white;
  cursor: pointer;
  z-index: 10001;
`;
function Init3D(store) {
  // debugger;
  if (!Detector.webgl) Detector.addGetWebGLMessage();
  init(store);
  animate();
}
function Destory3D(store, SetShow3D) {
  //debugger;
  cancelAnimationFrame(ani);
  window.removeEventListener("resize", onWindowResize);
  //debugger;
  camera = null;
  store.changeIsRender3D(false);
  container.style.filter = "blur(50px)";
  container.style.opacity = "0";

  renderer.domElement.addEventListener("dblclick", null, false);
  scene = null;
  camera = null;
  controls = null;
  //clock = null;
  empty(container);

  //setTimeout(function(){ container.style['z-index'] = '-1';}, 1500);
  store.changeIsRender3D(false);
  SetShow3D(false);
}
function init(store) {
  container = document.getElementById("3d-container");
  container.style["z-index"] = "10000";
  container.style.opacity = 1;
  camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 1, 10000);
  camera.position.set(350, 250, -150);

  scene = new THREE.Scene();

  clock = new THREE.Clock();

  // collada
  // debugger;
  let loader = new THREE.ColladaLoader();
  loader.load(store.ThreeDUrl, collada => {
    let animations = collada.animations;
    let avatar = collada.scene;
    // debugger;
    scene.add(avatar);

    container.style.filter = "blur(0px)";
  });
  // debugger;
  //

  let gridHelper = new THREE.GridHelper(100, 200);
  scene.add(gridHelper);

  //

  let ambientLight = new THREE.AmbientLight(0xffffff, 300);
  //scene.add(ambientLight);

  // let directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
  // // directionalLight.position.set(150, 150, - 150);
  // // scene.add(directionalLight);

  //
  // var light2 = new THREE.DirectionalLight( 0xffffff, 1000 );
  // light.position.set(, 250, -150);
  // scene.add( light2 );

  var light = new THREE.DirectionalLight(0xffffff, 1000);
  light.position.set(350, 250, -150);
  scene.add(light);

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  container.appendChild(renderer.domElement);

  //

  controls = new THREE.OrbitControls(camera, renderer.domElement);
  controls.minDistance = 300;
  controls.target.set(0, 2, 0);
  controls.update();

  //

  window.addEventListener("resize", onWindowResize, false);
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
}

function animate() {
  ani = requestAnimationFrame(animate);

  render();
}
function render() {
  var delta = clock.getDelta();

  if (mixer !== undefined) {
    mixer.update(delta);
  }

  renderer.render(scene, camera);
}
function empty(elem) {
  elem.removeChild(elem.lastChild);
}
class ThreeDViewer extends PureComponent {
  componentDidMount() {
    //debugger;
    Init3D(this.props.store);
  }
  render() {
    const { store, SetShow3D } = this.props;
    return (
      <div>
        <ThreeColser onClick={() => Destory3D(store, SetShow3D)}>X</ThreeColser>
        <div
          id="3d-container"
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: "10000",
            opacity: 1,
            transition: "0.45s opacity ease-in-out,1.5s filter ease-in-out 0.45s",
            filter: "blur(10px)"
          }}
        />
      </div>
    );
  }
}

export default ThreeDViewer;
