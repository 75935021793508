import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ResizeObserver from 'resize-observer-polyfill';
import { observer } from 'mobx-react';

import {
  Root,
  LogoWrapper,
  Name,
  ChName,
  EngName,
  NavItem,
  NavList,
  Icon
} from './styled';
import MobileNav from './MobileNav';
import MENU from '../../img/ICON/menu.svg';
import Logo from '../../Logo';


const COLLASPE = 1100;

class Nav extends Component {
  state = {
    mobileLogo: false,
    showMobileNav: this.props.store.showMobileNav,
  };

  componentDidMount() {
    this.handleResize();
    const resizeObserver = new ResizeObserver(this.handleResize);
    resizeObserver.observe(this.nav);
    if (this.props.match.isExact) {
      this.showNav();
    }
  }


  handleResize = () => {
    if (!this.nav) {
      return;
    }
    const element = this.nav.children[0];
    if (
      !this.props.store.isCollaspe &&
      element.scrollWidth > element.offsetWidth
    ) {
      this.props.store.changeIsCollaspe(true);
      this.props.store.changeNavStyle('show');
    }
    if (!this.state.mobileLogo && element.offsetWidth < 980) {
      this.setState({
        mobileLogo: true
      });
    }

    if (this.state.mobileLogo && element.offsetWidth > 980) {
      this.setState({
        mobileLogo: false
      });
    }
    if (this.props.store.isCollaspe && element.offsetWidth >= COLLASPE) {
      this.props.store.changeIsCollaspe(false);
      this.props.store.changeNavStyle('show');
    }
  };

  showNav = () => {
    if (!this.props.store.isCollaspe) {
      setTimeout(() => {
        this.props.store.changeNavStyle('showTop');
      }, 500);
    }
  };

  onRouting = path => {
    this.props.history.push(path);
  };

  closeMobileNav = () => {
    this.props.store.toggleMobileNav(false);
  };

  handleClickOutside = () => {
    this.closeMobileNav();
  };

  render() {
    const {
      store,
      match,
      onBuildingClick,
      onContactClick,
      onProgressClick
    } = this.props;

    return (
      <div
        ref={s => {
          this.nav = s;
        }}
      >
        <Root
          pose={match.isExact ? store.navStyle : 'show'}
          style={{
            position: match.isExact ? 'absolute' : 'relative',
            height: this.props.store.isCollaspe ? '55px' : '62px'
          }}
        >
          <Link to="/">
            <LogoWrapper>
              <Logo color="#fff" />
              <Name>
                <ChName>
                  {!this.state.mobileLogo
                    ? '聖得福建設開發股份有限公司'
                    : '聖得福建設'}
                </ChName>
                {!this.state.mobileLogo && (
                  <EngName>
                    Sheng De Fu Construction Development Co.,Ltd
                  </EngName>
                )}
              </Name>
            </LogoWrapper>
          </Link>
          {!this.props.store.isCollaspe ? (
            <NavList>
              <NavItem
                onClick={
                  match.isExact
                    ? () => store.scrollTo(store.news)
                    : () => this.onRouting('/#news')
                }
              >
                最新消息
              </NavItem>
              <NavItem
                onClick={
                  match.isExact
                    ? () => store.scrollTo(store.about)
                    : () => this.onRouting('/#about')
                }
              >
                關於我們
              </NavItem>
              <NavItem
                onClick={
                  match.isExact
                    ? () => store.scrollTo(store.building)
                    : () => this.onRouting('/#building')
                }
              >
                建案實績
              </NavItem>
              <NavItem
                onClick={
                  match.isExact
                    ? () => store.scrollTo(store.urban)
                    : () => this.onRouting('/#urban')
                }
              >
                都更專案
              </NavItem>
              <NavItem
                onClick={
                  match.isExact
                    ? () => store.scrollTo(store.progress)
                    : () => this.onRouting('/#progress')
                }
              >
                工程進度
              </NavItem>
              <NavItem
                onClick={
                  match.isExact
                    ? () => store.scrollTo(store.sales)
                    : () => this.onRouting('/#sales')
                }
              >
                租售專區
              </NavItem>
              <NavItem
                onClick={
                  match.isExact
                    ? () => store.scrollTo(store.contact)
                    : () => this.onRouting('/#contact')
                }
              >
                聯絡我們
              </NavItem>
            </NavList>
          ) : (
            <Icon
              innerRef={s => {
                this.icon = s;
              }}
              onClick={() => {
                this.setState({
                  showMobileNav: !this.state.showMobileNav
                });
                store.toggleMobileNav(!this.state.showMobileNav);
              }}
            >
              <img
                ref={s => {
                  this.img = s;
                }}
                src={MENU}
                alt=""
              />
            </Icon>
          )}
        </Root>
        {store.showMobileNav && (
          <MobileNav
            handleClickOutside={() => {
              this.handleClickOutside();
            }}
            onHomeClick={() => {
              this.closeMobileNav();
            }}
            onNewsClick={
              match.isExact
                ? () => {
                  store.scrollTo(store.news);
                  this.closeMobileNav();
                }
                : () => {
                  this.onRouting('/#news');
                  this.closeMobileNav();
                }
            }
            onAboutClick={() => {
              store.scrollTo(store.about);
              this.closeMobileNav();
            }}
            onBuildingClick={
              onBuildingClick
                ? () => {
                  store.scrollTo(store.building);
                  this.closeMobileNav();
                }
                : () => {
                  this.onRouting('/#building');
                  this.closeMobileNav();
                }
            }
            onUrbanClick={() => {
              store.scrollTo(store.urban);
              this.closeMobileNav();
            }}
            onSalesClick={
              onProgressClick
                ? () => {
                  store.scrollTo(store.sales);
                  this.closeMobileNav();
                }
                : () => {
                  this.onRouting('/#sales');
                  this.closeMobileNav();
                }
            }
            onProgressClick={
              onProgressClick
                ? () => {
                  store.scrollTo(store.progress);
                  this.closeMobileNav();
                }
                : () => {
                  this.onRouting('/#progress');
                  this.closeMobileNav();
                }
            }
            onContactClick={
              onContactClick
                ? () => {
                  store.scrollTo(store.contact);
                  this.closeMobileNav();
                }
                : () => {
                  this.onRouting('/#contact');
                  this.closeMobileNav();
                }
            }
          />
        )}
      </div>
    );
  }
}

export default observer(Nav);
