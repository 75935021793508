import React from "react";
import styled from "styled-components";
import { pure, compose } from "recompose";
import Loadable from "react-loadable";

import LayoutWrapper from "../Layout//LayoutWrapper";
import InnerSideBar from "../InnerComponents/InnerSideBar";
import InnerSlideAlbum from "../InnerComponents/InnerSlideAlbum";
import InnerSubTitle from "../InnerComponents/InnerSubTitle";
import InnerTable from "../InnerComponents/InnerTable";
import Inner3D from "../InnerComponents/Inner3D";
// import InnerYoutube from '../InnerComponents/InnerYoutube';
import InnerDownload from "../InnerComponents/InnerDownLoad";
import InnerUrbanRenewal from "./InnerUrbanRenewal";
import Loading from "../Loading";

import LayoutBlock from "../Layout/LayoutBlock";

const Root = styled.div`
  ${"" /* height: 100%; */} position: relative;
  ${"" /* width: 100%; */};
`;
const MainPanel = styled.div`
  margin: auto;
`;

const LoadableYoutube = Loadable({
  loader: () => import("../InnerComponents/InnerYoutube"),
  loading: Loading,
  render(loaded, props) {
    const Component = loaded.default;
    return <Component {...props} />;
  }
});

const UrbanRenewalInner = ({ urban, match, linkResolver, store, SetShow3D, ...props }) => {
  const result = urban.results.filter(ur => ur.uid === match.params.id);
  if (result[0]) {
    store.changeTaipeiMapArea(result[0].data.place);

    const { data } = result[0];
    console.log(data);
    return (
      // <Root>
      <LayoutWrapper
        {...props}
        match={match}
        store={store}
        title="都更專案"
        engTitle="Urban Renewal"
        hasTop
        scroll
        absolute
        url="../images/banner/Building_Banner.png"
        renderSideBar={<InnerSideBar data={urban.results} store={store} />}
      >
        <Root>
          <MainPanel>
            {/* <InnerTitle /> */}

            <LayoutBlock>
              <InnerSlideAlbum album={data.album} />
              <InnerSubTitle title={data.title} desc={data.desc} linkResovler={linkResolver} />
              {data.body.map((table, i) => <InnerTable key={i} data={table} />)}
              {data["3d"].url && <Inner3D data={data["3d"].url} store={store} SetShow3D={SetShow3D} />}
              {data.movie.url && <LoadableYoutube movie={data.movie} />}
            </LayoutBlock>
            <LayoutBlock>
              {data.attachment.length > 0 && (
                <InnerDownload
                  data={data.attachment}
                  password={data.password}
                  principal={data.principal}
                  phonenumber={data.phonenumber}
                  extension={data.extension}
                />
              )}
            </LayoutBlock>
            {data.body1.length > 0 && <InnerUrbanRenewal data={data.body1} linkResovler={linkResolver} />}
          </MainPanel>
        </Root>
      </LayoutWrapper>
      // </Root>
    );
  }
  return <LayoutWrapper>Not Found</LayoutWrapper>;
};

export default compose(pure)(UrbanRenewalInner);
