import React, { Fragment } from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';

import { SubmitButton } from '../Form';
import '../../Scss/Job.css';

const Root = styled.div`
  max-width: 600px;
  margin: auto;
  @media (max-width: 700px) {
    padding: 10px 45px;
  }
`;

const Content = styled.div`
  text-align: left;
`;

const Title = styled.div`
`;

const JobInner = ({ job, linkResolver }) => (
  <Root className="job">
    {job ? (
      <Fragment>
        {job.data.title && <Title>{RichText.render(job.data.title, linkResolver)}</Title>}
        <Content>{RichText.render(job.data.content, linkResolver)}</Content>
        <Link to="/jobs/apply">
          <SubmitButton>應徵</SubmitButton>
        </Link>
      </Fragment>
    ) : null}
  </Root>
);

export default JobInner;
