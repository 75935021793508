import React from 'react';
import styled, { keyframes } from 'styled-components';
import Logo from './Logo';

const scaleUp = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const Root = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${scaleUp} 1s;
`;

const LogoLoading = () => (
  <Root>
    <Logo size="110px" />
  </Root>
);

export default LogoLoading;
